import React, { useContext, useEffect, useState, createRef } from 'react'
import Typography from '../../../components/Typography';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BCGMatrix from './BCGMatrix';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LineChart from '../Sections/ProgramOptimization_graduation';
import BarAndLineGraph from '../Sections/ProgramOptimization_retention'
import { useMediaQuery } from "@mui/material";
export default function Dashboard() {
  const [selectedGoals, setSelectedGoals] = useState([]);
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const [chartType, setChartType] = React.useState('program_success');
  const ref = createRef(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const goalsData = [
    {
      "id": 1,
      "title": "No Poverty",
      "description": "End poverty in all its forms everywhere.",
      "color": "#e5243b",
      "selected": false
    },
    {
      "id": 2,
      "title": "Zero Hunger",
      "description": "End hunger, achieve food security and improved nutrition, and promote sustainable agriculture.",
      "color": "#dda63a",
      "selected": false
    },
    {
      "id": 3,
      "title": "Good Health and Well-being",
      "description": "Ensure healthy lives and promote well-being for all at all ages.",
      "color": "#4c9f38",
      "selected": false
    },
    {
      "id": 4,
      "title": "Quality Education",
      "description": "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
      "color": "#c5192d",
      "selected": false
    },
    {
      "id": 5,
      "title": "Gender Equality",
      "description": "Achieve gender equality and empower all women and girls.",
      "color": "#ff3a21",
      "selected": false
    },
    {
      "id": 6,
      "title": "Clean Water and Sanitation",
      "description": "Ensure availability and sustainable management of water and sanitation for all.",
      "color": "#26bde2",
      "selected": false
    },
    {
      "id": 7,
      "title": "Affordable and Clean Energy",
      "description": "Ensure access to affordable, reliable, sustainable, and modern energy for all.",
      "color": "#fcc30b",
      "selected": false
    },
    {
      "id": 8,
      "title": "Decent Work and Economic Growth",
      "description": "Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.",
      "color": "#a21942",
      "selected": false
    },
    {
      "id": 9,
      "title": "Industry, Innovation, and Infrastructure",
      "description": "Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation.",
      "color": "#fd6925",
      "selected": false
    },
    {
      "id": 10,
      "title": "Reduced Inequality",
      "description": "Reduce inequality within and among countries.",
      "color": "#dd1367",
      "selected": false
    },
    {
      "id": 11,
      "title": "Sustainable Cities and Communities",
      "description": "Make cities and human settlements inclusive, safe, resilient, and sustainable.",
      "color": "#fd9d24",
      "selected": false
    },
    {
      "id": 12,
      "title": "Responsible Consumption and Production",
      "description": "Ensure sustainable consumption and production patterns.",
      "color": "#bf8b2e",
      "selected": false
    },
    {
      "id": 13,
      "title": "Climate Action",
      "description": "Take urgent action to combat climate change and its impacts.",
      "color": "#3f7e44",
      "selected": false
    },
    {
      "id": 14,
      "title": "Life Below Water",
      "description": "Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.",
      "color": "#0a97d9",
      "selected": false
    },
    {
      "id": 15,
      "title": "Life on Land",
      "description": "Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
      "color": "#56c02b",
      "selected": false
    },
    {
      "id": 16,
      "title": "Peace, Justice, and Strong Institutions",
      "description": "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels.",
      "color": "#00689d",
      "selected": false
    },
    {
      "id": 17,
      "title": "Partnerships for the Goals",
      "description": "Strengthen the means of implementation and revitalize the global partnership for sustainable development.",
      "color": "#19486a",
      "selected": false
    }
  ];
  const [chartData, setChartData] = React.useState({
    xaxisTitle: 'Relative Market Share (%)',
    yaxisTitle: 'Market Growth Rate (%)',
    yThreshold: 0,
    xThreshold: 0.5,
    products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
    { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: 20.0 },
    { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
    { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
    { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: 'line',
        x0: 0.5, // Adjust this value to set the position of the line
        x1: 0.5, // Adjust this value to set the position of the line
        y0: -50,
        y1: 100, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: 'line',
        x0: 0,
        x1: 1, // Adjust this value to set the position of the line
        y0: 0, // Adjust this value to set the position of the line
        y1: 0, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
    ],
  });
  const handleCheckboxToggle = (goalId) => {
    // Check if the goal is already selected
    if (selectedGoals.includes(goalId)) {
      // If selected, remove it from the selected goals list
      setSelectedGoals(selectedGoals.filter(id => id !== goalId));
    } else {
      // If not selected, add it to the selected goals list
      setSelectedGoals([...selectedGoals, goalId]);
    }
  };
  const changeChartData = (event) => {
    let type = event.target.value;

    setChartType(event.target.value);
    switch (type) {
      case "program_success":
        setChartData({
          xaxisTitle: 'Relative Market Share (%)',
          yaxisTitle: 'Market Growth Rate (%)',
          yThreshold: 0,
          xThreshold: 0.5,
          products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
          { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: 20.0 },
          { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
          { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
          { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
          ],
          shapes: [
            // Add a line parallel to the x-axis
            {
              type: 'line',
              x0: 0.5, // Adjust this value to set the position of the line
              x1: 0.5, // Adjust this value to set the position of the line
              y0: -50,
              y1: 100, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
            // Add a line parallel to the y-axis
            {
              type: 'line',
              x0: 0,
              x1: 1, // Adjust this value to set the position of the line
              y0: 0, // Adjust this value to set the position of the line
              y1: 0, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
          ],
        })
        break;
      default:
    }
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', padding: '4em', width: '100%',
      marginLeft: isDesktop ? '312px' : '0px',
      flexDirection: 'column'
    }}>
      <Box sx={{
        width: '100%',
        height: '1em',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // mt: '4em'
      }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          OKRs - Goals
        </Typography>
      </Box>
      {/* <Typography variant="h6" marked="center" component="caption" sx={{ mb: 4 }}>
        The Goal is to move <span style={{ color: '#f1e15b' }}>cash cows</span> to <span style={{ color: '#61cdbb' }}>stars</span>
      </Typography>
      <Typography variant="h5" marked="center" component="caption" sx={{ mb: 4 }}>
        Target Program <span style={{ color: '#f1e15b' }}>Business Administration</span>
      </Typography> */}

      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        {/* Section 1: Checkbox list */}
        <div style={{ flex: 1 }}>
          <h2>Goals</h2>
          <ul style={{ listStyleType: 'none' }}>
            {goalsData.map(goal => (
              <li key={goal.id} style={{ padding: '5px' }}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedGoals.includes(goal.id)}
                    onChange={() => handleCheckboxToggle(goal.id)}
                  />
                  {goal.title}
                </label>
              </li>
            ))}
          </ul>
        </div>
        {/* Section 2: Description of selected goals */}
        <div style={{ flex: 2, maxHeight: '75vh', overflowY: 'auto' }}>
          <h2>Selected Goals</h2>
          <ul>
            {selectedGoals.map(goalId => {
              const goal = goalsData.find(item => item.id === goalId);
              return (
                <div key={goal.id}
                  // style={{ marginBottom: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', padding: '20px', backgroundColor: goal.color }}
                  style={{
                    color: 'black',
                    width: '100%',
                    background: `linear-gradient(to right,${goal.color},rgb(255, 255, 255))`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '20px',
                    overflow: 'hidden',
                    // borderRadius:'2%'
                  }}
                >
                  <h3 style={{
                    fontSize: '1.5em',
                    fontWeight: '600',
                  }}>{goal.title}</h3>
                  <p style={{
                    fontSize: '0.9em'
                  }}>{goal.description}</p>
                </div>
              );
            })}
          </ul>
        </div>
      </div>

      {/* <><div ref={ref} style={{
        height: '100%',
        display: 'flex'
      }}><Box sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        borderRadius: '5px',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor:secondaryColor
        alignItems: 'center',
        flexDirection: 'column',
        margin: '1em 0em 1em 0em'
      }}>
          {chartType === "program_success" && <>
            <Box sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}><BCGMatrix chartData={chartData} /></Box></>}
          {chartType === "program_success" && <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '1.2em',
            gap: '1rem'
          }}>
            <div style={{ backgroundColor: '#8ea9b0', width: '20px', height: '20px' }}></div>
            <div>Stars</div>
            <div style={{ backgroundColor: '#e1f0e1', width: '20px', height: '20px' }}></div>
            <div>Question Marks</div>
            <div style={{ backgroundColor: '#f9e9cf', width: '20px', height: '20px' }}></div>
            <div>Cash Cows</div>
            <div style={{ backgroundColor: '#fabfb9', width: '20px', height: '20px' }}></div>
            <div>Dogs</div>
          </div>}
          {chartType === "student_success" && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',  // Display items in a row
              justifyContent: 'center',
              alignItems: "center",  // Add space between the charts
              width: '100%',  // Adjust the total width as needed
              height: '100%',
            }}>
              <Box sx={{
                width: '48%',  // Adjust the width of the first chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <BarAndLineGraph />
              </Box>
              <Box sx={{
                width: '100%',  // Adjust the width of the second chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <LineChart />
              </Box>
            </Box>
          )}

          {chartType === "program_success" && <NoMaxWidthTooltip title={<React.Fragment><Box>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Market Growth Rate (%) = {' '}
              {'('}
              (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
              {')'}
              &times; 100
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Relative Market Share (%) = {' '}
              {'('}
              Program’s Market Share/Largest Market Share in the Same Context
              {')'}
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Program’s Market Share = {' '}
              {'('}
              Enrollment of the Program/Total Enrollment of All Programs
              {')'}
            </Typography>
          </Box></React.Fragment>}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </NoMaxWidthTooltip>}
        </Box>
      </div>
      </> */}

    </Box>


  )
}
