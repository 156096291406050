import * as React from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#413631",
        color: "#FFF7ED",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

    backgroundColor: "#FFF7ED",

}));
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Goal 1', "desc", "desc", "desc", "desc"),
    createData('Goal 2', "desc", "desc", "desc", "desc"),
    createData('Goal 3', "desc", "desc", "desc", "desc"),
    createData('Goal 4', "desc", "desc", "desc", "desc"),
    createData('Goal 5', "desc", "desc", "desc", "desc"),
    createData('Goal 6', "desc", "desc", "desc", "desc"),
    createData('Goal 7', "desc", "desc", "desc", "desc"),
    createData('Goal 8', "desc", "desc", "desc", "desc"),
    createData('Goal 9', "desc", "desc", "desc", "desc"),
    createData('Goal 10', "desc", "desc", "desc", "desc"),
    createData('Goal 11', "desc", "desc", "desc", "desc"),
    createData('Goal 12', "desc", "desc", "desc", "desc"),
    createData('Goal 13', "desc", "desc", "desc", "desc"),
    createData('Goal 14', "desc", "desc", "desc", "desc"),
    createData('Goal 15', "desc", "desc", "desc", "desc"),
    createData('Goal 16', "desc", "desc", "desc", "desc"),
    createData('Goal 17', "desc", "desc", "desc", "desc"),
];
export default function Table9() {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: '60vh '}}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Focus Area(s) Chosen by the School</StyledTableCell>
                        <StyledTableCell align="right">Societal Impact Strategy</StyledTableCell>
                        <StyledTableCell align="right">Outcomes Related to Curriculum (Standard 4)</StyledTableCell>
                        <StyledTableCell align="right">Outcomes Related to Scholarship (Standard 8)</StyledTableCell>
                        <StyledTableCell align="right">Outcomes Related to Internal and External Initiatives and/or Activities (Standard 9)
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}