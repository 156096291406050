import React, { useContext, useEffect, useState, createRef } from "react";
import Typography from "../../components/Typography";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BCGMatrix from "./BCGMatrix";
import { useTheme } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LineChart from "../Sections/BarLineChart_retention";
import BarAndLineGraph from "../Sections/BarGraph_growth_rate";
import { useMediaQuery } from "@mui/material";
import PLOCard from "../../components/PLOcard";
import axios from "axios";
import { annualGrowthRateDataMBA } from "../data/annual_growth_rate_data";
import { annualGrowthRateDataBSBA } from "../data/annual_growth_rate_data";
import { annualGrowthRateDataBSBAO } from "../data/annual_growth_rate_data";
import { annualGrowthRateDataTOP } from "../data/annual_growth_rate_data";

import { annualMarketShareDataMBA } from "../data/annual_growth_rate_data";
import { annualMarketShareDataBSBA } from "../data/annual_growth_rate_data";
import { annualMarketShareDataBSBAO } from "../data/annual_growth_rate_data";
import { annualMarketShareDataAll } from "../data/annual_growth_rate_data";

import { oneYearGradRateMBA } from "../data/mockdata_graduation_rate";
import { oneYearGradRate_SelfSupport_BSBA } from "../data/mockdata_graduation_rate";
import { twoYearGradRate_SelfSupport_BSBA } from "../data/mockdata_graduation_rate";
import { fourYearGradRate_SelfSupport_BSBA } from "../data/mockdata_graduation_rate";
import { sixYearGradRate_SelfSupport_BSBA } from "../data/mockdata_graduation_rate";

import { oneYearGradRate_SelfSupport_BSBA_Online } from "../data/mockdata_graduation_rate";
import { twoYearGradRate_SelfSupport_BSBA_Online } from "../data/mockdata_graduation_rate";
import { fourYearGradRate_SelfSupport_BSBA_Online } from "../data/mockdata_graduation_rate";
import { sixYearGradRate_SelfSupport_BSBA_Online } from "../data/mockdata_graduation_rate";

import { RetentionRate_MBA } from "../data/mockdata_retention_rate";
import { oneYearRetentionRate_BSBA } from "../data/mockdata_retention_rate";
import { twoYearRetentionRate_BSBA } from "../data/mockdata_retention_rate";
import { threeYearRetentionRate_BSBA } from "../data/mockdata_retention_rate";

import { oneYearRetentionRate_BSBA_Online } from "../data/mockdata_retention_rate";
import { twoYearRetentionRate_BSBA_Online } from "../data/mockdata_retention_rate";
import { threeYearRetentionRate_BSBA_Online } from "../data/mockdata_retention_rate";
import { enrolementOverTime_MBA } from "../data/mockdata_retention_rate";
import { enrolementOverTime_BSBA } from "../data/mockdata_retention_rate";
import { enrolementOverTime_BSBA_Online } from "../data/mockdata_retention_rate";
import { enrolementOverTime_Top } from "../data/mockdata_retention_rate";
import BarGraph from "../Sections/BarGraph_growth_rate";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function Dashboard() {
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const [chartType, setChartType] = React.useState("mba_concentration");
  const [value, setValue] = React.useState(0);
  const [StudentSuccessTabvalue, setStudentSuccessTabValue] = React.useState(0);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const ref = createRef(null);
  const [chartData, setChartData] = React.useState({
    xaxisTitle: "Relative Market Share (%)",
    yaxisTitle: "Market Growth Rate (%)",
    chartTitle: "BCG Matrix: All Programs",
    yThreshold: 85.9,
    xThreshold: 0.5,
    products: [
      {
        university_code: "CSUEB",
        program_code: "MBA_Analytics",
        program_name: "Analytics for managers",
        ProgramPortfolio: "MBA_Analytics",
        Enrollment: {
          2018: 3,
          2019: 13,
          2020: 26,
          2021: 27,
          2022: 23,
          2023: 21,
        },
        relative_market_share: 0.00525,
        FiveYearGrowthRate: 600,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA_Finance",
        program_name: "Finance",
        ProgramPortfolio: "MBA_Finance",
        Enrollment: {
          2018: 55,
          2019: 43,
          2020: 35,
          2021: 37,
          2022: 29,
          2023: 19,
        },
        relative_market_share: 0.00475,
        FiveYearGrowthRate: -65,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA_General_Business",
        program_name: "MBA : General Business",
        ProgramPortfolio: "MBA_General_Business",
        Enrollment: {
          2018: 9,
          2019: 25,
          2020: 32,
          2021: 45,
          2022: 34,
          2023: 40,
        },
        relative_market_share: 0.01,
        FiveYearGrowthRate: 344,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "HRM_OB",
        program_name:
          "MBA : Human Resources Management and Organizational Behavior",
        ProgramPortfolio: "HRM_OB",
        Enrollment: {
          2018: 27,
          2019: 25,
          2020: 30,
          2021: 49,
          2022: 45,
          2023: 32,
        },
        relative_market_share: 0.008,
        FiveYearGrowthRate: 19,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "ITM",
        program_name: "MBA : Information Technology Management",
        ProgramPortfolio: "ITM",
        Enrollment: {
          2018: 11,
          2019: 3,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Marketing_Management",
        program_name: "MBA : Marketing Management",
        ProgramPortfolio: "Marketing_Management",
        Enrollment: {
          2018: 17,
          2019: 14,
          2020: 14,
          2021: 20,
          2022: 29,
          2023: 28,
        },
        relative_market_share: 0.007,
        FiveYearGrowthRate: 65,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "OSCM",
        program_name: "MBA : Operations & Supply Chain Management",
        ProgramPortfolio: "OSCM",
        Enrollment: {
          2018: 25,
          2019: 31,
          2020: 24,
          2021: 23,
          2022: 24,
          2023: 14,
        },
        relative_market_share: 0.00525,
        FiveYearGrowthRate: -44,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Strategy_and_Innovation",
        program_name: "MBA : Strategy and Innovation",
        ProgramPortfolio: "Strategy_and_Innovation",
        Enrollment: {
          2018: 14,
          2019: 18,
          2020: 27,
          2021: 24,
          2022: 22,
          2023: 19,
        },
        relative_market_share: 0.00525,
        FiveYearGrowthRate: 36,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Executive_MBA_Global_Innovators",
        program_name: "Executive MBA Global Innovators",
        ProgramPortfolio: "Executive_MBA_Global_Innovators",
        Enrollment: {
          2018: 94,
          2019: 99,
          2020: 81,
          2021: 76,
          2022: 60,
          2023: 60,
        },
        relative_market_share: 0.015,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "One_Year_Intensive_MBA",
        program_name: "One Year Intensive MBA",
        ProgramPortfolio: "One_Year_Intensive_MBA",
        Enrollment: {},
        relative_market_share: 0,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Business_Analytics",
        program_name: "MS Business Analytics",
        ProgramPortfolio: "MS_Business_Analytics",
        Enrollment: {
          2018: 148,
          2019: 172,
          2020: 169,
          2021: 170,
          2022: 289,
          2023: 305,
        },
        relative_market_share: 0.07625,
        FiveYearGrowthRate: 106,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Accountancy",
        program_name: "MS Accountancy",
        ProgramPortfolio: "MS_Accountancy",
        Enrollment: {
          2018: 30,
          2019: 46,
          2020: 59,
          2021: 58,
          2022: 49,
          2023: 40,
        },
        relative_market_share: 0.01,
        FiveYearGrowthRate: 33,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Quantative_Economics",
        program_name: "MS Quantative Economics",
        ProgramPortfolio: "MS_Quantative_Economics",
        Enrollment: {
          2018: 38,
          2019: 25,
          2020: 21,
          2021: 19,
          2022: 13,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Accounting",
        program_name: "Accounting",
        ProgramPortfolio: "BSBA_Accounting",
        Enrollment: {
          2018: 476,
          2019: 443,
          2020: 421,
          2021: 432,
          2022: 429,
          2023: 463,
        },
        relative_market_share: 0.11575,
        FiveYearGrowthRate: -3,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Business_Economics",
        program_name: "BSBA : Business economics",
        ProgramPortfolio: "BSBA_Business_Economics",
        Enrollment: {
          2018: 45,
          2019: 44,
          2020: 55,
          2021: 48,
          2022: 31,
          2023: 23,
        },
        relative_market_share: 0.00575,
        FiveYearGrowthRate: -49,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Corporate_Management",
        program_name: "BSBA : Corporate management",
        ProgramPortfolio: "BSBA_Corporate_Management",
        Enrollment: {
          2018: 8,
          2019: 9,
          2020: 6,
          2021: 8,
          2022: 4,
          2023: 2,
        },
        relative_market_share: 0.0005,
        FiveYearGrowthRate: -75,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Entrepreneurship",
        program_name: "BSBA : Entrepreneurship",
        ProgramPortfolio: "BSBA_Entrepreneurship",
        Enrollment: {
          2018: 148,
          2019: 132,
          2020: 128,
          2021: 117,
          2022: 114,
          2023: 116,
        },
        relative_market_share: 0.029,
        FiveYearGrowthRate: -22,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Finance",
        program_name: "BSBA : Finance",
        ProgramPortfolio: "BSBA_Finance",
        Enrollment: {
          2018: 326,
          2019: 397,
          2020: 402,
          2021: 391,
          2022: 348,
          2023: 309,
        },
        relative_market_share: 0.07725,
        FiveYearGrowthRate: -5,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_General_Business",
        program_name: "BSBA : General business/General Management",
        ProgramPortfolio: "BSBA_General_Business",
        Enrollment: {
          2018: 575,
          2019: 656,
          2020: 684,
          2021: 627,
          2022: 573,
          2023: 558,
        },
        relative_market_share: 0.1395,
        FiveYearGrowthRate: -3,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_HRM_OB",
        program_name:
          "BSBA : Human Resources Management & Organizational Behavior",
        ProgramPortfolio: "BSBA_HRM_OB",
        Enrollment: {
          2018: 184,
          2019: 205,
          2020: 216,
          2021: 220,
          2022: 208,
          2023: 247,
        },
        relative_market_share: 0.06175,
        FiveYearGrowthRate: 34,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_ITM",
        program_name: "BSBA : Information Technology Management",
        ProgramPortfolio: "BSBA_ITM",
        Enrollment: {
          2018: 212,
          2019: 231,
          2020: 237,
          2021: 235,
          2022: 210,
          2023: 244,
        },
        relative_market_share: 0.061,
        FiveYearGrowthRate: 15,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Marketing_Management",
        program_name: "BSBA : Marketing management",
        ProgramPortfolio: "BSBA_Marketing_Management",
        Enrollment: {
          2018: 385,
          2019: 395,
          2020: 405,
          2021: 393,
          2022: 354,
          2023: 351,
        },
        relative_market_share: 0.08775,
        FiveYearGrowthRate: -9,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_OSCM",
        program_name: "BSBA : Operations & Supply Chain Management",
        ProgramPortfolio: "BSBA_OSCM",
        Enrollment: {
          2018: 76,
          2019: 85,
          2020: 92,
          2021: 113,
          2022: 119,
          2023: 115,
        },
        relative_market_share: 0.02875,
        FiveYearGrowthRate: 51,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Business_Analytics_Concentrations",
        program_name: "BS Business Analytics with Concentration(s)",
        ProgramPortfolio: "BSBA_Business_Analytics_Concentrations",
        Enrollment: {},
        relative_market_share: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Accounting_Analytics",
        program_name: "BS Accounting and Analytics",
        ProgramPortfolio: "BSBA_Accounting_Analytics",
        Enrollment: {},
        relative_market_share: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Economics",
        program_name: "BS : Economics",
        ProgramPortfolio: "BSBA_Economics",
        Enrollment: {
          2018: 89,
          2019: 78,
          2020: 87,
          2021: 69,
          2022: 49,
          2023: 49,
        },
        relative_market_share: 0.01225,
        FiveYearGrowthRate: -45,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Accounting",
        program_name: "Accounting",
        ProgramPortfolio: "BSBA_Online_Accounting",
        Enrollment: {
          2018: 2,
          2019: 3,
          2020: 30,
          2021: 99,
          2022: 180,
          2023: 227,
        },
        relative_market_share: 0.05675,
        FiveYearGrowthRate: 11250,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Business_Economics",
        program_name: "BSBA Online : Business economics",
        ProgramPortfolio: "BSBA_Online_Business_Economics",
        Enrollment: {
          2021: 1,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Corporate_Management",
        program_name: "BSBA Online : Corporate management",
        ProgramPortfolio: "BSBA_Online_Corporate_Management",
        Enrollment: {
          2018: 3,
          2019: 6,
          2020: 5,
          2021: 7,
          2022: 3,
          2023: 2,
        },
        relative_market_share: 0.0005,
        FiveYearGrowthRate: -33,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Entrepreneurship",
        program_name: "BSBA Online : Entrepreneurship",
        ProgramPortfolio: "BSBA_Online_Entrepreneurship",
        Enrollment: {
          2021: 1,
          2023: 1,
        },
        relative_market_share: 0.00025,
        FiveYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Finance",
        program_name: "BSBA Online : Finance",
        ProgramPortfolio: "BSBA_Online_Finance",
        Enrollment: {
          2018: 91,
          2019: 143,
          2020: 155,
          2021: 156,
          2022: 139,
          2023: 121,
        },
        relative_market_share: 0.03025,
        FiveYearGrowthRate: 33,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_General_Business",
        program_name: "BSBA Online : General business/General Management",
        ProgramPortfolio: "BSBA_Online_General_Business",
        Enrollment: {
          2018: 196,
          2019: 235,
          2020: 255,
          2021: 249,
          2022: 244,
          2023: 211,
        },
        relative_market_share: 0.05275,
        FiveYearGrowthRate: 8,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_HRM_OB",
        program_name: "BSBA Online : HRM&OB/HRM",
        ProgramPortfolio: "BSBA_Online_HRM_OB",
        Enrollment: {
          2021: 19,
          2022: 67,
          2023: 82,
        },
        relative_market_share: 0.0325,
        FiveYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_ITM",
        program_name: "BSBA Online : Information Technology Management",
        ProgramPortfolio: "BSBA_Online_ITM",
        Enrollment: {
          2018: 50,
          2019: 68,
          2020: 78,
          2021: 108,
          2022: 91,
          2023: 110,
        },
        relative_market_share: 0.0275,
        FiveYearGrowthRate: 120,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Marketing_Management",
        program_name: "BSBA Online : Marketing management",
        ProgramPortfolio: "BSBA_Online_Marketing_Management",
        Enrollment: {
          2018: 2,
          2019: 1,
          2021: 4,
          2022: 34,
          2023: 62,
        },
        relative_market_share: 0.0155,
        FiveYearGrowthRate: 3000,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_OSCM",
        program_name: "BSBA Online : Operations & Supply Chain Management",
        ProgramPortfolio: "BSBA_Online_OSCM",
        Enrollment: {
          2018: 34,
          2019: 42,
          2020: 48,
          2021: 67,
          2022: 69,
          2023: 69,
        },
        relative_market_share: 0.01725,
        FiveYearGrowthRate: 103,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: "line",
        // x0: 0.5,
        // x1: 0.5,
        // y0: -50,
        // y1: 100,
        x0: 0,
        x1: 1,
        y0: 506.56,
        y1: 506.56,
        line: {
          color: "black",
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: "line",
        x0: 0.5,
        x1: 0.5,
        y0: 12000,
        y1: -12000,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
  });
  const [mbaWithConcentrations, setMbaWithConcentrations] = React.useState({
    xaxisTitle: "Relative Market Share (%)",
    yaxisTitle: "Market Growth Rate (%)",
    chartTitle: "MBA with concentrations for latest year (2023)",
    yThreshold: -20.39,
    xThreshold: 0.11,
    products: [
      {
        university_code: "CSUEB",
        program_code: "MBA",
        program_name: "MBA",
        ProgramPortfolio: "MBA",
        Enrollment: {
          2018: 44,
          2019: 26,
          2020: 35,
          2021: 26,
          2022: 16,
          2023: 8,
        },
        relative_market_share: 0.0441,
        FiveYearGrowthRate: -82,
        LatestYearGrowthRate: -50,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA_Analytics",
        program_name: "Analytics for managers",
        ProgramPortfolio: "MBA_Analytics",
        Enrollment: {
          2018: 3,
          2019: 13,
          2020: 26,
          2021: 27,
          2022: 23,
          2023: 21,
        },
        relative_market_share: 0.116,
        FiveYearGrowthRate: 600,
        LatestYearGrowthRate: -8.69,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA_Finance",
        program_name: "Finance",
        ProgramPortfolio: "MBA_Finance",
        Enrollment: {
          2018: 55,
          2019: 43,
          2020: 35,
          2021: 37,
          2022: 29,
          2023: 19,
        },
        relative_market_share: 0.1049,
        FiveYearGrowthRate: -65,
        LatestYearGrowthRate: -34.48,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA_General_Business",
        program_name: "General Business",
        ProgramPortfolio: "MBA_General_Business",
        Enrollment: {
          2018: 9,
          2019: 25,
          2020: 32,
          2021: 45,
          2022: 34,
          2023: 40,
        },
        relative_market_share: 0.2209,
        FiveYearGrowthRate: 344,
        LatestYearGrowthRate: 17.64,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "HRM_OB",
        program_name: "Human Resources Management and Organizational Behavior",
        ProgramPortfolio: "HRM_OB",
        Enrollment: {
          2018: 27,
          2019: 25,
          2020: 30,
          2021: 49,
          2022: 45,
          2023: 32,
        },
        relative_market_share: 0.1767,
        FiveYearGrowthRate: 19,
        LatestYearGrowthRate: -28.8,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "ITM",
        program_name: "Information Technology Management",
        ProgramPortfolio: "ITM",
        Enrollment: {
          2018: 11,
          2019: 3,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Marketing_Management",
        program_name: "Marketing Management",
        ProgramPortfolio: "Marketing_Management",
        Enrollment: {
          2018: 17,
          2019: 14,
          2020: 14,
          2021: 20,
          2022: 29,
          2023: 28,
        },
        relative_market_share: 0.1546,
        FiveYearGrowthRate: 65,
        LatestYearGrowthRate: -3.44,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "OSCM",
        program_name: "Operations & Supply Chain Management",
        ProgramPortfolio: "OSCM",
        Enrollment: {
          2018: 25,
          2019: 31,
          2020: 24,
          2021: 23,
          2022: 24,
          2023: 14,
        },
        relative_market_share: 0.0773,
        FiveYearGrowthRate: -44,
        first_year_retention: -41.66,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Strategy_and_Innovation",
        program_name: "Strategy and Innovation",
        ProgramPortfolio: "Strategy_and_Innovation",
        Enrollment: {
          2018: 14,
          2019: 18,
          2020: 27,
          2021: 24,
          2022: 22,
          2023: 19,
        },
        relative_market_share: 0.1049,
        FiveYearGrowthRate: 36,
        LatestYearGrowthRate: -13.63,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: "line",
        x0: 0,
        x1: 1,
        y0: -20.39,
        y1: -20.39,
        line: {
          color: "black",
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: "line",
        x0: 0.11,
        x1: 0.11,
        y0: 20,
        y1: -60,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
  });
  const [bsbaOnlineChartData, setbsbaOnlineChartData] = React.useState({
    xaxisTitle: "Relative Market Share (%)",
    yaxisTitle: "Market Growth Rate (%)",
    chartTitle: "BSBA Online with Concentrations for latest year (2023)",
    yThreshold: 6.82,
    xThreshold: 0.09,
    products: [
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Accounting",
        program_name: "Accounting",
        ProgramPortfolio: "BSBA_Online_Accounting",
        Enrollment: {
          2018: 2,
          2019: 3,
          2020: 30,
          2021: 99,
          2022: 180,
          2023: 227,
        },
        relative_market_share: 0.243,
        FiveYearGrowthRate: 11250,
        LatestYearGrowthRate: 26.11,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Business_Economics",
        program_name: "Business economics",
        ProgramPortfolio: "BSBA_Online_Business_Economics",
        Enrollment: {
          2021: 1,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Corporate_Management",
        program_name: "Corporate management",
        ProgramPortfolio: "BSBA_Online_Corporate_Management",
        Enrollment: {
          2018: 3,
          2019: 6,
          2020: 5,
          2021: 7,
          2022: 3,
          2023: 2,
        },
        relative_market_share: 0.002,
        FiveYearGrowthRate: -33,
        LatestYearGrowthRate: -33.33,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Entrepreneurship",
        program_name: "Entrepreneurship",
        ProgramPortfolio: "BSBA_Online_Entrepreneurship",
        Enrollment: {
          2021: 1,
          2023: 1,
        },
        relative_market_share: 0.001,
        FiveYearGrowthRate: 0,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Finance",
        program_name: "Finance",
        ProgramPortfolio: "BSBA_Online_Finance",
        Enrollment: {
          2018: 91,
          2019: 143,
          2020: 155,
          2021: 156,
          2022: 139,
          2023: 121,
        },
        relative_market_share: 0.13,
        FiveYearGrowthRate: 33,
        LatestYearGrowthRate: -12.94,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_General_Business",
        program_name: "General business/General Management",
        ProgramPortfolio: "BSBA_Online_General_Business",
        Enrollment: {
          2018: 196,
          2019: 235,
          2020: 255,
          2021: 249,
          2022: 244,
          2023: 211,
        },
        relative_market_share: 0.226,
        FiveYearGrowthRate: 8,
        LatestYearGrowthRate: -13.52,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_HRM_OB",
        program_name: "HRM&OB/HRM",
        ProgramPortfolio: "BSBA_Online_HRM_OB",
        Enrollment: {
          2021: 19,
          2022: 67,
          2023: 82,
        },
        relative_market_share: 0.14,
        FiveYearGrowthRate: 0,
        LatestYearGrowthRate: 58.53,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_ITM",
        program_name: "Information Technology Management",
        ProgramPortfolio: "BSBA_Online_ITM",
        Enrollment: {
          2018: 50,
          2019: 68,
          2020: 78,
          2021: 108,
          2022: 91,
          2023: 110,
        },
        relative_market_share: 0.118,
        FiveYearGrowthRate: 120,
        LatestYearGrowthRate: 20.87,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_Marketing_Management",
        program_name: "Marketing management",
        ProgramPortfolio: "BSBA_Online_Marketing_Management",
        Enrollment: {
          2018: 2,
          2019: 1,
          2021: 4,
          2022: 34,
          2023: 62,
        },
        relative_market_share: 0.066,
        FiveYearGrowthRate: 3000,
        LatestYearGrowthRate: 82.35,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online_OSCM",
        program_name: "Operations & Supply Chain Management",
        ProgramPortfolio: "BSBA_Online_OSCM",
        Enrollment: {
          2018: 34,
          2019: 42,
          2020: 48,
          2021: 67,
          2022: 69,
          2023: 69,
        },
        relative_market_share: 0.073,
        FiveYearGrowthRate: 103,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: "line",
        // x0: 0.090,
        // x1: 0.090,
        // y0: -50,
        // y1: 100,
        x0: 0,
        x1: 1,
        y0: 6.82,
        y1: 6.82,
        line: {
          color: "black",
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: "line",
        x0: 0.09,
        x1: 0.09,
        y0: 100,
        y1: -100,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
  });
  const [bsbaChartData, setBSBAChartData] = React.useState({
    xaxisTitle: "Relative Market Share (%)",
    yaxisTitle: "Market Growth Rate (%)",
    chartTitle: "BSBA with Concentrations for latest year (2023)",
    yThreshold: -5.3,
    xThreshold: 0.11,
    products: [
      {
        university_code: "CSUEB",
        program_code: "BSBA without concentration",
        program_name: "BSBA without concentration",
        ProgramPortfolio: "BSBA without concentration",
        Enrollment: {
          2018: 141,
          2019: 65,
          2020: 47,
          2021: 15,
          2022: 8,
          2023: 2,
        },
        relative_market_share: 0.0008,
        FiveYearGrowthRate: -98,
        LatestYearGrowthRate: -2.7,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Accounting",
        program_name: "Accounting",
        ProgramPortfolio: "BSBA_Accounting",
        Enrollment: {
          2018: 476,
          2019: 443,
          2020: 421,
          2021: 432,
          2022: 429,
          2023: 463,
        },
        relative_market_share: 0.1905,
        FiveYearGrowthRate: -3,
        LatestYearGrowthRate: 7.92,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Business_Economics",
        program_name: "Business economics",
        ProgramPortfolio: "BSBA_Business_Economics",
        Enrollment: {
          2018: 45,
          2019: 44,
          2020: 55,
          2021: 48,
          2022: 31,
          2023: 23,
        },
        relative_market_share: 0.00946,
        FiveYearGrowthRate: -49,
        LatestYearGrowthRate: -25.8,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Corporate_Management",
        program_name: "Corporate management",
        ProgramPortfolio: "BSBA_Corporate_Management",
        Enrollment: {
          2018: 8,
          2019: 9,
          2020: 6,
          2021: 8,
          2022: 4,
          2023: 2,
        },
        relative_market_share: 0.0008,
        FiveYearGrowthRate: -75,
        LatestYearGrowthRate: -50,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Entrepreneurship",
        program_name: "Entrepreneurship",
        ProgramPortfolio: "BSBA_Entrepreneurship",
        Enrollment: {
          2018: 148,
          2019: 132,
          2020: 128,
          2021: 117,
          2022: 114,
          2023: 116,
        },
        relative_market_share: 0.0477,
        FiveYearGrowthRate: -22,
        LatestYearGrowthRate: 1.75,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Finance",
        program_name: "Finance",
        ProgramPortfolio: "BSBA_Finance",
        Enrollment: {
          2018: 326,
          2019: 397,
          2020: 402,
          2021: 391,
          2022: 348,
          2023: 309,
        },
        relative_market_share: 0.1271,
        FiveYearGrowthRate: -5,
        LatestYearGrowthRate: -11.2,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_General_Business",
        program_name: "General business/General Management",
        ProgramPortfolio: "BSBA_General_Business",
        Enrollment: {
          2018: 575,
          2019: 656,
          2020: 684,
          2021: 627,
          2022: 573,
          2023: 558,
        },
        relative_market_share: 0.2296,
        FiveYearGrowthRate: -3,
        LatestYearGrowthRate: -2.61,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_HRM_OB",
        program_name: "Human Resources Management & Organizational Behavior",
        ProgramPortfolio: "BSBA_HRM_OB",
        Enrollment: {
          2018: 184,
          2019: 205,
          2020: 216,
          2021: 220,
          2022: 208,
          2023: 247,
        },
        relative_market_share: 0.1016,
        FiveYearGrowthRate: 34,
        LatestYearGrowthRate: 18.75,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_ITM",
        program_name: "Information Technology Management",
        ProgramPortfolio: "BSBA_ITM",
        Enrollment: {
          2018: 212,
          2019: 231,
          2020: 237,
          2021: 235,
          2022: 210,
          2023: 244,
        },
        relative_market_share: 0.1004,
        FiveYearGrowthRate: 15,
        LatestYearGrowthRate: 16.2,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Marketing_Management",
        program_name: "Marketing management",
        ProgramPortfolio: "BSBA_Marketing_Management",
        Enrollment: {
          2018: 385,
          2019: 395,
          2020: 405,
          2021: 393,
          2022: 354,
          2023: 351,
        },
        relative_market_share: 0.1444,
        FiveYearGrowthRate: -9,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_OSCM",
        program_name: "Operations & Supply Chain Management",
        ProgramPortfolio: "BSBA_OSCM",
        Enrollment: {
          2018: 76,
          2019: 85,
          2020: 92,
          2021: 113,
          2022: 119,
          2023: 115,
        },
        relative_market_share: 0.0473,
        FiveYearGrowthRate: 51,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: "line",
        // x0: 0.11,
        // x1: 0.11,
        // y0: -50,
        // y1: 100,
        x0: 0,
        x1: 1,
        y0: -5.3,
        y1: -5.3,
        line: {
          color: "black",
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: "line",
        x0: 0.11,
        x1: 0.11,
        y0: 50,
        y1: -60,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
  });
  const [topLevelChartData, setTopLevelChartData] = React.useState({
    xaxisTitle: "Relative Market Share (%)",
    yaxisTitle: "Market Growth Rate (%)",
    chartTitle: "All Programs for latest year (2023)",
    yThreshold: -2.2,
    xThreshold: 0.083,
    products: [
      {
        university_code: "CSUEB",
        program_code: "BSBA",
        program_name: "BSBA",
        ProgramPortfolio: "BSBA",
        Enrollment: {
          2018: 2576,
          2019: 2662,
          2020: 2693,
          2021: 2599,
          2022: 2398,
          2023: 2430,
        },
        relative_market_share: 0.6076,
        FiveYearGrowthRate: -6,
        LatestYearGrowthRate: 1.33,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Online",
        program_name: "BSBA Online",
        ProgramPortfolio: "BSBA_Online",
        Enrollment: {
          2018: 458,
          2019: 534,
          2020: 623,
          2021: 768,
          2022: 845,
          2023: 934,
        },
        relative_market_share: 0.2335,
        FiveYearGrowthRate: 104,
        LatestYearGrowthRate: 10.53,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BS Business Analytics",
        program_name: "BS Business Analytics",
        ProgramPortfolio: "BS Business Analytics",
        Enrollment: {},
        relative_market_share: 0,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Accounting_Analytics",
        program_name: "BS Accounting and Analytics",
        ProgramPortfolio: "BSBA_Accounting_Analytics",
        Enrollment: {},
        relative_market_share: 0,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "BSBA_Economics",
        program_name: "BS : Economics",
        ProgramPortfolio: "BSBA_Economics",
        Enrollment: {
          2018: 89,
          2019: 78,
          2020: 87,
          2021: 69,
          2022: 49,
          2023: 49,
        },
        relative_market_share: 0.01225,
        FiveYearGrowthRate: -45,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": "",
        "150_percent_completion": "",
        number_of_plo: 0,
        number_of_plo_assessed: 0,
      },
      {
        university_code: "CSUEB",
        program_code: "MBA",
        program_name: "MBA",
        ProgramPortfolio: "MBA",
        Enrollment: {
          2018: 205,
          2019: 198,
          2020: 223,
          2021: 251,
          2022: 222,
          2023: 181,
        },
        relative_market_share: 0.04525,
        FiveYearGrowthRate: -12,
        LatestYearGrowthRate: -18.46,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Business_Analytics",
        program_name: "MS Business Analytics",
        ProgramPortfolio: "MS_Business_Analytics",
        Enrollment: {
          2018: 148,
          2019: 172,
          2020: 169,
          2021: 170,
          2022: 289,
          2023: 305,
        },
        relative_market_share: 0.07625,
        FiveYearGrowthRate: 106,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Accountancy",
        program_name: "MS Accountancy",
        ProgramPortfolio: "MS_Accountancy",
        Enrollment: {
          2018: 30,
          2019: 46,
          2020: 59,
          2021: 58,
          2022: 49,
          2023: 40,
        },
        relative_market_share: 0.01,
        FiveYearGrowthRate: 33,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "Executive MBA for Global Innovators",
        program_name: "Executive MBA for Global Innovators",
        ProgramPortfolio: "Executive MBA for Global Innovators",
        Enrollment: {
          2018: 94,
          2019: 99,
          2020: 81,
          2021: 76,
          2022: 60,
          2023: 60,
        },
        relative_market_share: 0.015,
        FiveYearGrowthRate: -36,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "One_Year_Intensive_MBA",
        program_name: "One Year Intensive MBA",
        ProgramPortfolio: "One_Year_Intensive_MBA",
        Enrollment: {},
        relative_market_share: 0,
        LatestYearGrowthRate: 0,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
      {
        university_code: "CSUEB",
        program_code: "MS_Quantative_Economics",
        program_name: "MS Quantative Economics",
        ProgramPortfolio: "MS_Quantative_Economics",
        Enrollment: {
          2018: 38,
          2019: 25,
          2020: 21,
          2021: 19,
          2022: 13,
        },
        relative_market_share: 0,
        FiveYearGrowthRate: -100,
        first_year_retention: 10,
        "100_percent_completion": 10,
        "150_percent_completion": 10,
        number_of_plo: 10,
        number_of_plo_assessed: 7,
      },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: "line",
        // x0: 0.5,
        // x1: 0.5,
        // y0: -50,
        // y1: 100,
        x0: 0,
        x1: 1,
        y0: -2.2,
        y1: -2.2,
        line: {
          color: "black",
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: "line",
        x0: 0.083,
        x1: 0.083,
        y0: 20,
        y1: -20,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
  });
  React.useEffect(() => {
    // getProgramsData();
    // console.log(annualGrowthRateData)
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeStudentSuccess = (event, newValue) => {
    setStudentSuccessTabValue(newValue);
  };
  const getProgramsData = async () => {
    await axios
      .get(
        "https://wavrmolvj2.execute-api.us-west-1.amazonaws.com/dev/HigherEd_API/programs"
      )
      .then((data) => console.log(data.data.Items))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeChartData = (event) => {
    let type = event.target.value;

    setChartType(event.target.value);
    switch (type) {
      case "program_success":
        setChartData({
          xaxisTitle: "Relative Market Share (%)",
          yaxisTitle: "Market Growth Rate (%)",
          yThreshold: 0,
          xThreshold: 0.5,
          products: [
            {
              name: "Accountancy",
              marketShare: 0.012,
              marketGrowthRate: -22.5,
            },
            {
              name: "Business Administration",
              marketShare: 0.87,
              marketGrowthRate: -0.18,
            },
            {
              name: "Business Analytics",
              marketShare: 0.098,
              marketGrowthRate: 27.6,
            },
            { name: "Economics", marketShare: 0.016, marketGrowthRate: -20.96 },
            {
              name: "Quantitative Economics",
              marketShare: 0.0011,
              marketGrowthRate: 100,
            },
          ],
          shapes: [
            // Add a line parallel to the x-axis
            {
              type: "line",
              x0: 0.5, // Adjust this value to set the position of the line
              x1: 0.5, // Adjust this value to set the position of the line
              y0: -50,
              y1: 100, // Adjust this value to set the position of the line
              line: {
                color: "black",
                width: 2,
              },
            },
            // Add a line parallel to the y-axis
            {
              type: "line",
              x0: 0,
              x1: 1, // Adjust this value to set the position of the line
              y0: 0, // Adjust this value to set the position of the line
              y1: 0, // Adjust this value to set the position of the line
              line: {
                color: "black",
                width: 2,
              },
            },
          ],
        });
        break;
      default:
    }
  };

  const getProductColor = (product) => {
    // You can customize the color mapping based on your preferences
    if (
      product.FiveYearGrowthRate >= chartData.yThreshold &&
      product.relative_market_share <= chartData.xThreshold
    ) {
      return "#f6511d"; // Question Marks
    } else if (
      product.FiveYearGrowthRate >= chartData.yThreshold &&
      product.relative_market_share > chartData.xThreshold
    ) {
      return "#ffb400"; // Stars
    } else if (
      product.FiveYearGrowthRate < chartData.yThreshold &&
      product.relative_market_share >= chartData.xThreshold
    ) {
      return "#00a6ed"; // Cash Cows
    } else {
      return "#7fb800"; // Dogs
    }
  };
  const LegendBox = ({ color, title, description }) => {
    return (
      <div
        style={{
          // padding: '0.5em',
          display: "flex",
          // flexDirection: 'row',
          height: "100%",
          // marginBottom: '1.2em',
          alignItems: "center",
          // marginLeft: '5em',
          // marginRight: '5em',
          justifyContent: "space-between",
          // backgroundColor: '#F5EDE5',
          // borderRadius: '20px',
          // gap: '2rem'
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#F5EDE5",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "#F5EDE5",
              padding: "1.5em",
              borderRadius: "10px",
              maxWidth: "300px",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffb400",
                width: "100%",
                height: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div>High Program Portfolio Share High Growth Rate</div>
          </div>
          <div
            style={{
              backgroundColor: "#F5EDE5",
              padding: "1.5em",
              borderRadius: "10px",
              maxWidth: "300px",
            }}
          >
            <div
              style={{
                backgroundColor: "#f6511d",
                width: "100%",
                height: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div>Low Program Portfolio Share High Growth Rate</div>
          </div>
          <div
            style={{
              backgroundColor: "#F5EDE5",
              padding: "1.5em",
              borderRadius: "10px",
              maxWidth: "300px",
            }}
          >
            <div
              style={{
                backgroundColor: "#00a6ed",
                width: "100%",
                height: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div>High Program Portfolio Share Low Growth Rate</div>
          </div>
          <div
            style={{
              backgroundColor: "#F5EDE5",
              padding: "1.5em",
              borderRadius: "10px",
              maxWidth: "300px",
            }}
          >
            <div
              style={{
                backgroundColor: "#7fb800",
                width: "100%",
                height: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div>Low Program Portfolio Share Low Growth Rate</div>
          </div>
        </div>
      </div>
    );
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });
  const displayPrograms = () => {
    return chartData.products.map((items) => (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <span
            style={{
              minWidth: "15px",
              height: "15px",
              backgroundColor: getProductColor(items),
              borderRadius: "50%",
              marginTop: "1em",
            }}
          ></span>
          <Typography variant="h5" component="span">
            {items.program_name}{" "}
            {"(" +
              items.number_of_plo_assessed +
              "/" +
              items.number_of_plo +
              ")"}
          </Typography>
        </div>
      </>
    ));
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "4em",
        width: "100%",
        // marginLeft: isDesktop ? '312px' : '0px',
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "1em",
          padding: "1em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // mt: '4em'
        }}
      >
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Program Review
        </Typography>
      </Box>
      <Box
        sx={{
          minWidth: 120,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: "1em",
        }}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Select</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chartType}
            label="Age"
            onChange={changeChartData}
            sx={{ backgroundColor: secondaryColor.main, width: "300px" }}
          >
            <MenuItem value={"mba_concentration"}>
              MBA with Concentrations{" "}
            </MenuItem>
            <MenuItem value={"bsba_concentration"}>
              BSBA with Concentrations{" "}
            </MenuItem>
            <MenuItem value={"bsba_online_concentration"}>
              BSBA online with Concentrations{" "}
            </MenuItem>
            <MenuItem value={"top_level"}>All Programs </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <>
        <div
          ref={ref}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              borderRadius: "20px",
              padding: "1em",
              display: "flex",
              justifyContent: "center",
              // backgroundColor:'#FDFAF6',backgroundColor: '#F5EDE5'
              alignItems: "center",
              flexDirection: "column",
              // margin: '1em 0em 1em 0em'
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "1.5em", color: secondaryColor.dark }}
                  variant="h5"
                  component="span"
                >
                  Program Success
                </Typography>
                <Tabs
                  sx={{ display: "flex" }}
                  value={value}
                  onChange={handleChange}
                >
                  <Tab sx={{ color: secondaryColor.dark }} label="BCG Matrix" />
                  <Tab
                    sx={{ color: secondaryColor.dark }}
                    label="Growth Rate"
                  />
                  <Tab
                    sx={{ color: secondaryColor.dark }}
                    label="Market Share"
                  />
                  <Tab sx={{ color: secondaryColor.dark }} label="Enrollment" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  sx={{ display: "flex", gap: "2em", flexDirection: "column" }}
                >
                  {chartType === "mba_concentration" && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "60vh",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <BCGMatrix chartData={mbaWithConcentrations} />
                      </Box>
                      <LegendBox></LegendBox>
                      {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}><NoMaxWidthTooltip title={<React.Fragment><Box>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Market Growth Rate (%) = {' '}
                      {'('}
                      (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
                      {')'}
                      &times; 100
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Relative Market Share (%) = {' '}
                      {'('}
                      Program’s Market Share/Largest Market Share in the Same Context
                      {')'}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Program’s Market Share = {' '}
                      {'('}
                      Enrollment of the Program/Total Enrollment of All Programs
                      {')'}
                    </Typography>
                  </Box></React.Fragment>}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </NoMaxWidthTooltip>
                  </Box> */}
                    </Box>
                  )}
                  {chartType === "bsba_online_concentration" && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <BCGMatrix chartData={bsbaOnlineChartData} />
                      </Box>
                      <LegendBox></LegendBox>
                      {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}><NoMaxWidthTooltip title={<React.Fragment><Box>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Market Growth Rate (%) = {' '}
                      {'('}
                      (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
                      {')'}
                      &times; 100
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Relative Market Share (%) = {' '}
                      {'('}
                      Program’s Market Share/Largest Market Share in the Same Context
                      {')'}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      Program’s Market Share = {' '}
                      {'('}
                      Enrollment of the Program/Total Enrollment of All Programs
                      {')'}
                    </Typography>
                  </Box></React.Fragment>}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </NoMaxWidthTooltip>
                  </Box> */}
                    </Box>
                  )}
                  {chartType === "bsba_concentration" && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <BCGMatrix chartData={bsbaChartData} />
                      </Box>
                      <LegendBox></LegendBox>
                    </Box>
                  )}
                  {chartType === "top_level" && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <BCGMatrix chartData={topLevelChartData} />
                      </Box>
                      <LegendBox></LegendBox>
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <NoMaxWidthTooltip
                          title={
                            <React.Fragment>
                              <Box>
                                <Typography
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Market Growth Rate (%) = {"("}
                                  (Enrollment in the Latest Year - Enrollment in
                                  the Earliest Year)/Enrollment in the Earliest
                                  Year
                                  {")"}
                                  &times; 100
                                </Typography>
                                <Typography
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Relative Market Share (%) = {"("}
                                  Program’s Market Share/Largest Market Share in
                                  the Same Context
                                  {")"}
                                </Typography>
                                <Typography
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "14px",
                                  }}
                                >
                                  Program’s Market Share = {"("}
                                  Enrollment of the Program/Total Enrollment of
                                  All Programs
                                  {")"}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          }
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </NoMaxWidthTooltip>
                      </Box>
                    </Box>
                  )}
                </Box>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <Box
                  sx={{
                    width: "100%", // Adjust the width of the second chart
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {chartType === "mba_concentration" && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BarGraph data={annualGrowthRateDataMBA} />
                    </div>
                  )}
                  {chartType === "bsba_online_concentration" && (
                    <BarGraph data={annualGrowthRateDataBSBAO} />
                  )}
                  {chartType === "bsba_concentration" && (
                    <BarGraph data={annualGrowthRateDataBSBA} />
                  )}
                  {chartType === "top_level" && (
                    <BarGraph data={annualGrowthRateDataTOP} />
                  )}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box
                  sx={{
                    width: "100%", // Adjust the width of the second chart
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {chartType === "mba_concentration" && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BarGraph data={annualMarketShareDataMBA} />
                    </div>
                  )}
                  {chartType === "bsba_online_concentration" && (
                    <BarGraph data={annualMarketShareDataBSBAO} />
                  )}
                  {chartType === "bsba_concentration" && (
                    <BarGraph data={annualMarketShareDataBSBA} />
                  )}
                  {chartType === "top_level" && (
                    <BarGraph data={annualMarketShareDataAll} />
                  )}
                </Box>{" "}
                *
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Box
                  sx={{
                    width: "100%", // Adjust the width of the second chart
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {chartType === "mba_concentration" && (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BarGraph data={enrolementOverTime_MBA} />
                    </div>
                  )}
                  {chartType === "bsba_online_concentration" && (
                    <BarGraph data={enrolementOverTime_BSBA_Online} />
                  )}
                  {chartType === "bsba_concentration" && (
                    <BarGraph data={enrolementOverTime_BSBA} />
                  )}
                  {chartType === "top_level" && (
                    <BarGraph data={enrolementOverTime_Top} />
                  )}
                </Box>
              </CustomTabPanel>

              {/* <CustomTabPanel value={value} index={1}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',  // Display items in a row
                justifyContent: 'space-between',
                alignItems: "center", // Add space between the charts
                width: '100%',  // Adjust the total width as needed
                height: '100%',
              }}>
                <Box sx={{
                  width: '48%',  // Adjust the width of the first chart
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <LineChart />
                </Box>
                <Box sx={{
                  width: '48%',  // Adjust the width of the second chart
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <BarAndLineGraph />
                </Box>
              </Box>
            </CustomTabPanel> */}
            </Box>
            {/* {chartType === "program_success" && <>
            <Box sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}><BCGMatrix chartData={chartData} /></Box></>}
          {chartType === "program_success" && <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '1.2em',
            gap: '1rem'
          }}>
            <div style={{ backgroundColor: '#8ea9b0', width: '20px', height: '20px' }}></div>
            <div>Stars</div>
            <div style={{ backgroundColor: '#e1f0e1', width: '20px', height: '20px' }}></div>
            <div>Question Marks</div>
            <div style={{ backgroundColor: '#f9e9cf', width: '20px', height: '20px' }}></div>
            <div>Cash Cows</div>
            <div style={{ backgroundColor: '#fabfb9', width: '20px', height: '20px' }}></div>
            <div>Dogs</div>
          </div>}
          {chartType === "student_success" && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',  // Display items in a row
              justifyContent: 'space-between',
              alignItems: "center", // Add space between the charts
              width: '100%',  // Adjust the total width as needed
              height: '100%',
            }}>
              <Box sx={{
                width: '48%',  // Adjust the width of the first chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <LineChart />
              </Box>
              <Box sx={{
                width: '48%',  // Adjust the width of the second chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <BarAndLineGraph />
              </Box>
            </Box>
          )}

          {chartType === "program_success" && <NoMaxWidthTooltip title={<React.Fragment><Box>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Market Growth Rate (%) = {' '}
              {'('}
              (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
              {')'}
              &times; 100
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Relative Market Share (%) = {' '}
              {'('}
              Program’s Market Share/Largest Market Share in the Same Context
              {')'}
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Program’s Market Share = {' '}
              {'('}
              Enrollment of the Program/Total Enrollment of All Programs
              {')'}
            </Typography>
          </Box></React.Fragment>}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </NoMaxWidthTooltip>} */}
          </Box>
          <Box sx={{ display: "flex", gap: "1em" }}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                borderRadius: "20px",
                padding: "1em",
                display: "flex",
                // justifyContent: 'start',
                // backgroundColor:secondaryColor
                alignItems: "center",
                flexDirection: "column",
                margin: "1em 0em 1em 0em",
              }}
            >
              {/* <Typography variant="h6" component="span">Student Success</Typography> */}
              {/* <Box sx={{
              display: 'flex',
              flexDirection: 'column',  // Display items in a row
              // justifyContent: 'center',
              alignItems: "center", // Add space between the charts
              width: '100%',  // Adjust the total width as needed
              // height: '100%',
            }}> */}

              {/* <Box sx={{
              width: '48%',  // Adjust the width of the first chart
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <LineChart />
            </Box> */}
              {/* <Box sx={{
              width: '100%',  // Adjust the width of the second chart
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <BarGraph data={annualGrowthRateDataMBA} />
            </Box> */}
              {/* </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontSize: "1.5em", color: secondaryColor.dark }}
                  variant="h5"
                  component="span"
                >
                  Student Success
                </Typography>
                <Tabs
                  sx={{ display: "flex", justifyContent: "end" }}
                  value={StudentSuccessTabvalue}
                  onChange={handleChangeStudentSuccess}
                >
                  <Tab
                    sx={{ color: secondaryColor.dark }}
                    label="Graduation Rate"
                  />
                  <Tab
                    sx={{ color: secondaryColor.dark }}
                    label="Retention Rate"
                  />
                  <Tab
                    sx={{ color: secondaryColor.dark }}
                    label="Learning Outcome"
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={StudentSuccessTabvalue} index={0}>
                <Box sx={{ display: "flex", gap: "2em", flexDirection: "row" }}>
                  <Box
                    sx={{
                      width: "100%", // Adjust the width of the second chart
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    {chartType === "mba_concentration" && (
                      <>
                        <BarGraph data={oneYearGradRateMBA} />
                      </>
                    )}
                    {chartType === "bsba_online_concentration" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5em",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph
                              data={oneYearGradRate_SelfSupport_BSBA_Online}
                            />
                            <BarGraph
                              data={twoYearGradRate_SelfSupport_BSBA_Online}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph
                              data={fourYearGradRate_SelfSupport_BSBA_Online}
                            />
                            <BarGraph
                              data={sixYearGradRate_SelfSupport_BSBA_Online}
                            />
                          </div>
                        </Box>
                      </>
                    )}
                    {chartType === "bsba_concentration" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5em",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph data={oneYearGradRate_SelfSupport_BSBA} />
                            <BarGraph data={twoYearGradRate_SelfSupport_BSBA} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph
                              data={fourYearGradRate_SelfSupport_BSBA}
                            />
                            <BarGraph data={sixYearGradRate_SelfSupport_BSBA} />
                          </div>
                        </Box>
                      </>
                    )}
                    {chartType === "top_level" && <></>}
                  </Box>
                </Box>
              </CustomTabPanel>

              <CustomTabPanel value={StudentSuccessTabvalue} index={1}>
                <Box
                  sx={{ display: "flex", gap: "2em", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      width: "100%", // Adjust the width of the second chart
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {chartType === "mba_concentration" && (
                      <>
                        <BarGraph data={RetentionRate_MBA} />
                      </>
                    )}
                    {chartType === "bsba_online_concentration" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5em",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph data={oneYearRetentionRate_BSBA_Online} />
                            <BarGraph data={twoYearRetentionRate_BSBA_Online} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph
                              data={threeYearRetentionRate_BSBA_Online}
                            />
                            {/* <BarGraph data={sixYearGradRate_SelfSupport_BSBA_Online} /> */}
                          </div>
                        </Box>
                      </>
                    )}
                    {chartType === "bsba_concentration" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5em",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph data={oneYearRetentionRate_BSBA} />
                            <BarGraph data={twoYearRetentionRate_BSBA} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5em",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <BarGraph data={threeYearRetentionRate_BSBA} />
                            {/* <BarGraph data={sixYearGradRate_SelfSupport_BSBA} /> */}
                          </div>
                        </Box>
                      </>
                    )}
                    {chartType === "top_level" && <></>}
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={StudentSuccessTabvalue} index={2}>
                {/* <Typography variant="h6" component="span">Programs (# program outcome assessed)</Typography>
              {displayPrograms()} */}
                <Box sx={{ display: "flex", gap: "2em", flexDirection: "row" }}>
                  {/* <Box
                    sx={{
                      width: "100%", // Adjust the width of the second chart
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  > */}
                  {chartType === "mba_concentration" && (
                    <PLOCard program="MBA with Concentrations"></PLOCard>
                  )}
                  {chartType === "bsba_online_concentration" && (
                    <PLOCard program="BSBA Online"></PLOCard>
                  )}
                  {chartType === "bsba_concentration" && (
                    <PLOCard program="BSBA"></PLOCard>
                  )}
                  {chartType === "top_level" && (
                    <>
                      <PLOCard program="MBA with Concentrations"></PLOCard>
                      <PLOCard program="BSBA Online"></PLOCard>
                      <PLOCard program="BSBA"></PLOCard>
                    </>
                  )}
                  {/* </Box> */}
                </Box>
              </CustomTabPanel>
            </Box>
          </Box>
        </div>
      </>
    </Box>
  );
}
