import React, { useContext, useEffect, useState, createRef } from 'react'
import Typography from '../../../components/Typography';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BCGMatrix from './BCGMatrix';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LineChart from '../Sections/BarLineChart_retention';
import BarAndLineGraph from '../Sections/BarLineChart_graduation';
import { useMediaQuery } from "@mui/material";

export default function Dashboard() {
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const [chartType, setChartType] = React.useState('program_success');
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const ref = createRef(null);
  const [chartData, setChartData] = React.useState({
    xaxisTitle: 'Relative Market Share (%)',
    yaxisTitle: 'Market Growth Rate (%)',
    yThreshold: 0,
    xThreshold: 0.5,
    products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
    { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: -0.18 },
    { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
    { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
    { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: 'line',
        x0: 0.5, // Adjust this value to set the position of the line
        x1: 0.5, // Adjust this value to set the position of the line
        y0: -50,
        y1: 100, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: 'line',
        x0: 0,
        x1: 1, // Adjust this value to set the position of the line
        y0: 0, // Adjust this value to set the position of the line
        y1: 0, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
    ],
  });

  const changeChartData = (event) => {
    let type = event.target.value;

    setChartType(event.target.value);
    switch (type) {
      case "program_success":
        setChartData({
          xaxisTitle: 'Relative Market Share (%)',
          yaxisTitle: 'Market Growth Rate (%)',
          yThreshold: 0,
          xThreshold: 0.5,
          products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
          { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: -0.18 },
          { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
          { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
          { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
          ],
          shapes: [
            // Add a line parallel to the x-axis
            {
              type: 'line',
              x0: 0.5, // Adjust this value to set the position of the line
              x1: 0.5, // Adjust this value to set the position of the line
              y0: -50,
              y1: 100, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
            // Add a line parallel to the y-axis
            {
              type: 'line',
              x0: 0,
              x1: 1, // Adjust this value to set the position of the line
              y0: 0, // Adjust this value to set the position of the line
              y1: 0, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
          ],
        })
        break;
      default:
    }
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', padding: '4em', width: '100%',
      marginLeft: isDesktop ? '312px' : '0px',
      flexDirection: 'column'
    }}>
      <Box sx={{
        width: '100%',
        height: '1em',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // mt: '4em'
      }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Baseline Review
        </Typography>
      </Box>
     

    

    </Box>


  )
}
