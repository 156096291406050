export const oneYearGradRateMBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [, 0, 0, 0, 0, 0, 0, 0, 0],
            name: '1 Year Graduation Rate (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [43.9, 46.2, 45.5, 46.3, 38.1, 60.0, 52.3, 38.6, 43.6],
            name: '2 Year Graduation Rate (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [75.0, 66.7, 80.3, 83.3, 87.9, 86.7, 78.9, 85.3, 85.7],
            name: '4 Year Graduation Rate (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [75.0, , 91.7, , 84.6, 86.7, 83.3, 85.7, ,],
            name: '6 Year Graduation Rate (State Support)',
        },
    ],
    layout: {
        title: 'Graduation Rates for MBA',
        plot_bgcolor: '#F5EDE5', 
        paper_bgcolor: '#F5EDE5',
        xaxis: {
            automargin: true,
            title: '',
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['MBA without Concentration(s)',
                'Analytics for managers/Analytics for managers MBA',
                'Finance',
                'General business',
                'HRM&OB/HR&OB',
                'ITM',
                'Marketing management',
                'OSCM',
                'Strategy and innovation'], tickangle: -45
        },
        yaxis: { title: 'Annual Graduation Rate', range: [0, 100] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers",itemdoubleclick:"toggleothers", }, // Horizontal legend
        width: 1000,
        height: 700,
        // margin: { r: 200 }
        // autosize: true,
    }
}

export const oneYearGradRate_SelfSupport_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0.3, 0.2, 0, 0, 0.4, 0, 0], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '1 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 1] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const twoYearGradRate_SelfSupport_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [31.2, 25.0, 25.6, 37.7, 30.9, 34, 32, 29.8, 34.2, 18.3], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '2 Year Graduation Rate',
        // paper_bgcolor:'#28282a',

        autotickangles: true,
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            ticklabeloverflow: 'allow',
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 50] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const fourYearGradRate_SelfSupport_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [22.4, 10.7, 15.7, 25.5, 21.8, 42.9, 57.1, 21.3, 40, 26.3], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [77.7, 53.3, 65.4, 72.4, 76.0, 80.2, 74.5, 75.3, 77.3, 50.0], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '4 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const sixYearGradRate_SelfSupport_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [36, 33.3, 33.3, 40, 47.1, 50, 50, 34.5, 0, 57.1], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [81.5, 75, 53.1, 72.9, 72.4, 84.1, 78.7, 79.8, 94.1, 56.5], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '6 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',],
            tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 200] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}


export const oneYearGradRate_SelfSupport_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [1.3, 0, 0, 0.6, 0.9, 0, 0, 2.9, 0, 0],
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '1 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 5] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const twoYearGradRate_SelfSupport_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [11.7, 0, 0, 21.9, 19.3, 17.2, 23.6, 12.5, 16.8, 18.8],
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '2 Year Graduation Rate',
        // paper_bgcolor:'#28282a',

        autotickangles: true,
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            ticklabeloverflow: 'allow',
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 100] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        // width: 600,
        // height: 400,
        autosize: true,
    }
}
export const fourYearGradRate_SelfSupport_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [36.4, 0, 0, 52.9, 47.7, 0, 52.3, 100, 55.1, 55.4],
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '4 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 200] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        // width: 600,
        // height: 400,
        autosize: true,
    }
}
export const sixYearGradRate_SelfSupport_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [100, 0, 0, 67.4, 52.7, 0, 56.3, 100, 50, 59.4],
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '6 Year Graduation Rate',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'Acounting',
                'Business Economics',
                'Entrepreneurship',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'OSCM',
                'MBA without Concentration(s)',],
            tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        // width: 600,
        // height: 400,
        autosize: true,
    }
}
