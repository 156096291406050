import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';
import { useMediaQuery } from "@mui/material";
const BCGMatrix = (props) => {
    // console.log(props);
    const isDesktop = useMediaQuery("(min-width: 768px)");

    // useEffect(() => {
    //     Plot.resize()
    // }, [isDesktop]);
    console.log(isDesktop);
    if (props.chartData.products) {
        const plotData = props.chartData.products.map(product => ({
            type: 'scatter',
            mode: 'markers',
            x: [product.relative_market_share],
            y: [product.LatestYearGrowthRate],
            marker: {
                color: getProductColor(product, props.chartData),
                size: 15,
            },
            text: [product.program_name],
            name: product.program_name,
            connectgaps: true
        }));

        const layout = {
            plot_bgcolor: '#F5EDE5',
            legend: {
                orientation: 'v',  // Vertical legend orientation
                // x: 0,            // Align legend to the right of the plot
                // y: -0.5,
                itemclick: "toggleothers"
            },
            paper_bgcolor: '#F5EDE5',
            // modebar:{
            //     bgcolor:'black'
            // },
            // font:{
            //     size:20
            // },
            title: {
                // font: {
                //     size: 24,
                //     family: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                //     color: 'black'
                // },
                text: props.chartData.chartTitle,

            },
            xaxis: {
                title: {
                    text: props.chartData.xaxisTitle,
                    font: {
                        size: 18,
                        family: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                        color: 'black'
                    }
                }
            },
            yaxis: {
                title: {
                    text: props.chartData.yaxisTitle,
                    font: {
                        size: 18,
                        family: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                        color: 'black'
                    }
                }
            },
            hovermode: 'closest',
            shapes: props.chartData.shapes,
            autosize: true,
            
            // legend: {
            //     font:{
            //         size:30
            //     }
            // margin: {
            //     b: 100,  // Adjust bottom margin to create space for the legend
            // },
            // },
            // showlegend:false,
            // title: { font: 2 }
            // width: '100%',
            // height: '100%',
        };

        return <Plot data={plotData} layout={layout} style={{
            width: '100%',
            height: '100%',
            // borderRadius: '10px',
            // overflow: 'hidden',
        }} />;
    }
};

const getProductColor = (product, chartData) => {
    // You can customize the color mapping based on your preferences
    if (product.LatestYearGrowthRate >= chartData.yThreshold && product.relative_market_share <= chartData.xThreshold) {
        return '#f6511d'; // Question Marks
    } else if (product.LatestYearGrowthRate >= chartData.yThreshold && product.relative_market_share > chartData.xThreshold) {
        return '#ffb400'; // Stars
    } else if (product.LatestYearGrowthRate < chartData.yThreshold && product.relative_market_share >= chartData.xThreshold) {
        return '#00a6ed'; // Cash Cows
    } else {
        return '#7fb800'; // Dogs
    }
};

export default BCGMatrix;