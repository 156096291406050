import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../../components/AppBar";
import Toolbar from "../../components/Toolbar";
// import CompanyClients from "./CompanyClients";
// import AboutUs from "./AboutUs";
// import Services from "./Services";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";

const rightLink = {
  fontSize: 16,
  ml: 3,
};

const TopNavigation = (props) => {
  const myPropValue = props.myProp;
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <section>
      <AppBar
        position="fixed"
        sx={{
          display: "block",
          background: "rgba(246, 255, 248, 0.9)",
          boxShadow: "0 7px 50px 0 rgba(0,0,0,0.2)",
          padding: "0.5em 0em 0.1em 0em",
        }}
      >
        <Toolbar sx={{ justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ flex: 1, display: "flex", height: "1 px" }}>
            <img
              src="/sindex_logo.png"
              alt="Brave Spaces"
              maxWidth="10%"
              style={{ display: "flex", maxWidth: "14%", minWidth: "7em" }}
            />
          </Box>
          {isDesktop ? (
            <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <Link
                color="primary"
                href="#services"
                variant="h6"
                underline="none"
                sx={rightLink}
              >
                {"Services"}
              </Link>
              <Link
                variant="h6"
                color="primary"
                underline="none"
                sx={rightLink}
                href="#approach"
              >
                {"APPROACH"}
              </Link>
              <Link
                color="primary"
                variant="h6"
                underline="none"
                sx={rightLink}
                href="#about"
              >
                {"About"}
              </Link>
            </Box>
          ) : (
            <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
              <img alt="menu-icon" src="/menu-icon.png" />
            </button>
          )}

          {!isDesktop && isMenuToggled && (
            <div
              style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                height: "100%",
                width: "300px",
                flexDirection: "column",
                display: "block",
                opacity: "1",
                backgroundColor: "#FFF7ED",
              }}
            >
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                  <img alt="close-icon" src="/close.png" />
                </button>
              </div>

              <div style={{ marginTop: "2rem" }}>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    color="primary"
                    href="#services"
                    variant="h6"
                    underline="none"
                    sx={rightLink}
                  >
                    {"Services"}
                  </Link>
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    variant="h6"
                    color="primary"
                    underline="none"
                    sx={rightLink}
                    href="#client"
                  >
                    {"APPROACH"}
                  </Link>
                </div>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Link
                    color="primary"
                    variant="h6"
                    underline="none"
                    sx={rightLink}
                    href="#about"
                  >
                    {"About"}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </section>
  );
};

export default TopNavigation;
