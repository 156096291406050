import React from 'react';
import Plot from 'react-plotly.js';

const BarAndLineGraph = () => {
  // Data for bar and line graph
  const data = [
    {
      type: 'bar',
      x: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      y: [20, 25, 22, 28, 30, 35, 40, 38, 45], // Transfer students percentage
      name: 'Transfer Students',
      marker: { color: '#8ea9b0' }, // Blue color
    },
    {
      type: 'bar',
      x: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      y: [80, 75, 78, 72, 70, 65, 60, 62, 55], // Freshman students percentage
      name: 'Freshman Students',
      marker: { color: '#e1f0e1' }, // Red color
    },
    {
      type: 'scatter',
      x: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      y: [85.2, 86.5, 87.8, 98.3, 99.0, 89.5, 89.7, 89.8, 89.9], // Example line data for a program
      mode: 'lines+markers',
      name: 'Current Stats -MBA',
      line: { color:  '#fbbbbb' },
    },
    {
      type: 'scatter',
      x: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      y: [93.0, 94.2, 95.5, 96.8, 97.5, 98.0, 98.5, 99.0, 99.5], // Example line data for a program
      mode: 'lines+markers',
      name: 'Goal Stats-MBA',
      line: { color:  '#977070' },
    },
  ];

  // Layout configuration
  const layout = {
    title: 'Retention Percentage Over Years For Each Program',
    xaxis: { title: 'Year' },
    yaxis: { title: 'Retention Percentage (%)', range: [0.00, 100.00] }, // Adjust the range based on your data
    barmode: 'stack', // 'stack' for stacked bars
    legend: { orientation: 'h',y: -0.2 }, // Horizontal legend
  };

  return (
    <Plot
      data={data}
      layout={layout}
    />
  );
};

export default BarAndLineGraph;
