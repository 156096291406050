import React, { useState } from 'react'
import Typography from '../../components/Typography';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import LinearProgress from '@mui/material/LinearProgress';
import FormControl from '@mui/material/FormControl';
import MyResponsiveLine from '../Sections/LineChart';
import MyResponsiveLineUB from '../Sections/LineChart_UniqBelo';
import InputLabel from '@mui/material/InputLabel';
import TracekrVerticalBar from '../Sections/TracekrVerticalBar';
import { data } from "../data/mockdatahorizontalbar";
import { Button } from 'antd';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import BCGMatrix from './BCGMatrix';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
let data_graph = [
  {
    "id": "Uniqueness",
    "color": "hsl(110, 70%, 50%)",
    "data": [
      {
        "x": "Q 1",
        "y": 0
      },
      {
        "x": "Q 2",
        "y": 4
      },
      {
        "x": "Q 3",
        "y": 3
      },
      {
        "x": "Q 4",
        "y": 4
      },
    ]
  },
];

let data_graph_belongingness = [
  {
    "id": "Belongingness",
    "color": "hsl(110, 70%, 50%)",
    "data": [
      {
        "x": "Q 1",
        "y": 0
      },
      {
        "x": "Q 2",
        "y": 3
      },
      {
        "x": "Q 3",
        "y": 5
      },
      {
        "x": "Q 4",
        "y": 4.4
      },
    ]
  },
];

let data_race_NA = [
  {
    "id": "Company",
    "color": "hsl(78, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 1.4
      },
      {
        "x": "Feb",
        "y": 2.4
      },
      {
        "x": "Mar",
        "y": 2.4
      },
      {
        "x": "Apr",
        "y": 2.4
      },
      {
        "x": "May",
        "y": 3
      },
      {
        "x": "Jun",
        "y": 3
      },
      {
        "x": "July",
        "y": 5
      },
      {
        "x": "Aug",
        "y": 5
      },
      {
        "x": "Sep",
        "y": 5
      },
      {
        "x": "Oct",
        "y": 5
      },
      {
        "x": "Nov",
        "y": 5.5
      },
      {
        "x": "Dec",
        "y": 6
      }
    ]
  },
  {
    "id": "County",
    "color": "hsl(292, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 35.5
      },
      {
        "x": "Feb",
        "y": 35.5
      },
      {
        "x": "Mar",
        "y": 35.5
      },
      {
        "x": "Apr",
        "y": 40.0
      },
      {
        "x": "May",
        "y": 40.0
      },
      {
        "x": "Jun",
        "y": 41.0
      },
      {
        "x": "July",
        "y": 41.0
      },
      {
        "x": "Aug",
        "y": 41.0
      },
      {
        "x": "Sep",
        "y": 41.0
      },
      {
        "x": "Oct",
        "y": 41.0
      },
      {
        "x": "Nov",
        "y": 41.0
      },
      {
        "x": "Dec",
        "y": 41.0
      }
    ]
  },
];
let data_race_WH = [
  {
    "id": "Company",
    "color": "hsl(78, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 1
      },
      {
        "x": "Feb",
        "y": 2
      },
      {
        "x": "Mar",
        "y": 2
      },
      {
        "x": "Apr",
        "y": 2.4
      },
      {
        "x": "May",
        "y": 3
      },
      {
        "x": "Jun",
        "y": 7
      },
      {
        "x": "July",
        "y": 5
      },
      {
        "x": "Aug",
        "y": 5
      },
      {
        "x": "Sep",
        "y": 7
      },
      {
        "x": "Oct",
        "y": 6
      },
      {
        "x": "Nov",
        "y": 5.5
      },
      {
        "x": "Dec",
        "y": 6
      }
    ]
  },
  {
    "id": "County",
    "color": "hsl(292, 70%, 50%)",
    "data": [
      {
        "x": "Jan",
        "y": 35.5
      },
      {
        "x": "Feb",
        "y": 35.5
      },
      {
        "x": "Mar",
        "y": 35.5
      },
      {
        "x": "Apr",
        "y": 40.0
      },
      {
        "x": "May",
        "y": 40.0
      },
      {
        "x": "Jun",
        "y": 41.0
      },
      {
        "x": "July",
        "y": 41.0
      },
      {
        "x": "Aug",
        "y": 41.0
      },
      {
        "x": "Sep",
        "y": 41.0
      },
      {
        "x": "Oct",
        "y": 41.0
      },
      {
        "x": "Nov",
        "y": 41.0
      },
      {
        "x": "Dec",
        "y": 41.0
      }
    ]
  },
];


export default function Tracker() {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [selectedOption, setSelectedOption] = useState(1);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dataRace = [];
  // let chartData;

  // switch (selectedOption) {
  //   case 1:
  //     chartData = data_race_NA;
  //     break;
  //   case 2:
  //     chartData = data_race_WH;
  //     break;
  //   case 3:
  //     chartData = data_race_NA;
  //     break;
  //   case 4:
  //     chartData = data_race_WH;
  //     break;
  //   case 5:
  //     chartData = data_race_NA;
  //     break;
  //   case 6:
  //     chartData = data_race_WH;
  //     break;
  //   // Add more cases for other options if needed
  //   default:
  //     chartData = dataRace;
  // }



  const [activeBox, setActiveBox] = useState(1);

  const handleBoxClick = (event) => {
    setActiveBox(event.target.value);
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const chartData = {
    xaxisTitle: 'Relative Market Share (%)',
    yaxisTitle: 'Market Growth Rate (%)',
    yThreshold: 0,
    xThreshold: 0.5,
    products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
    { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: 20.0 },
    { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
    { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
    { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: 'line',
        x0: 0.5, // Adjust this value to set the position of the line
        x1: 0.5, // Adjust this value to set the position of the line
        y0: -50,
        y1: 100, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: 'line',
        x0: 0,
        x1: 1, // Adjust this value to set the position of the line
        y0: 0, // Adjust this value to set the position of the line
        y1: 0, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
    ],
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <div style={{ height: '100%', width: '100%', padding: '4em', 
    // marginLeft: isDesktop ? '312px' : '0px' 
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Impacts of intervention
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ minWidth: 275, background: '#FFF1FC' }}>
          <CardContent>
            <SentimentVeryDissatisfiedIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}Not{bull}Started{bull}
            </Typography>
            <LinearProgressWithLabel value='10' />
            <Typography color='red'>(1/10) tasks not yet started</Typography>
          </CardContent>

        </Card>
        <Card sx={{ minWidth: 275, background: '#F5F5EF' }}>
          <CardContent>
            <SentimentSatisfiedAltIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}In{bull}Progress{bull}
            </Typography>
            <LinearProgressWithLabel value='80' />
            <Typography color='#E4BB10'>(8/10) tasks in progress</Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275, background: '#C8E4DB' }}>
          <CardContent>
            <SentimentVerySatisfiedIcon />
            <Typography variant="h5" component="div">
              Tasks{bull}Completed
            </Typography>
            <LinearProgressWithLabel value='10' />
            <Typography color='green'>(1/10) tasks completed</Typography>
          </CardContent>
        </Card>
      </Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '25%', marginTop: '2%' }}>
        <Typography variant="h5" component="div" marginRight="2%">
          Diversity
        </Typography>
        <Select value={activeBox} onChange={handleBoxClick}>
          <MenuItem value={1}>Gender</MenuItem>
          <MenuItem value={2}>Ethinicity</MenuItem>
          <MenuItem value={3}>Race</MenuItem>
          <MenuItem value={4}>Veteran</MenuItem>
          <MenuItem value={5}>Disability</MenuItem>
        </Select>
      </Box>
      <Box display={activeBox === 1 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Gender</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Gender"
              >
                <MenuItem value={1}>Female</MenuItem>
                <MenuItem value={2}>Male</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 2 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Ethnicity</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Ethnicity"
              >
                <MenuItem value={1}>Hispanic</MenuItem>
                <MenuItem value={2}>Non Hispanic</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 3 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '70vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Race</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Race"
              >
                <MenuItem value={1}>Native American</MenuItem>
                <MenuItem value={2}>White</MenuItem>
                <MenuItem value={3}>Asian</MenuItem>
                <MenuItem value={4}>Black</MenuItem>
                <MenuItem value={5}>Pacific Islander</MenuItem>
                <MenuItem value={6}>Not Disclosed</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 4 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '60vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Veteran</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Veteran"
              >
                <MenuItem value={1}>Veteran</MenuItem>
                <MenuItem value={2}>Non Veteran</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box display={activeBox === 5 ? 'block' : 'none'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
          <Box sx={{
            width: '160vh',
            height: '60vh',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            borderRadius: '5px',
            padding: '1em',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '2em 0em 2em 0em',
          }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, mt: 5 }}>
              <InputLabel >Disability</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Disability"
              >
                <MenuItem value={1}>Disabled</MenuItem>
                <MenuItem value={2}>Not Disabled</MenuItem>
              </Select>
            </FormControl>
            <MyResponsiveLine data_graph={chartData}></MyResponsiveLine>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25%', }}>
        <Box sx={{
          width: '70vh',
          height: '40vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '2em 0em 2em 0em',
        }}>
          <Typography variant="body1">Uniqueness Index Over Time</Typography>
          <MyResponsiveLineUB data_graph={data_graph}></MyResponsiveLineUB>
        </Box>
        <Box sx={{
          width: '70vh',
          height: '40vh',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          borderRadius: '5px',
          padding: '1em',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '2em 0em 2em 0em',
          // marginLeft: '5%',
        }}>
          <Typography variant="body1">Belongingness Index Over Time</Typography>
          <MyResponsiveLineUB data_graph={data_graph_belongingness}></MyResponsiveLineUB>
        </Box>
      </Box> */}
      <><div ><Box sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        borderRadius: '5px',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor:secondaryColor
        alignItems: 'center',
        flexDirection: 'column',
        margin: '1em 0em 1em 0em'
      }}>
        <Box sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}><BCGMatrix chartData={chartData} /></Box>
        <NoMaxWidthTooltip title={<React.Fragment><Box>
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
            Market Growth Rate (%) = {' '}
            {'('}
            (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
            {')'}
            &times; 100
          </Typography>
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
            Relative Market Share (%) = {' '}
            {'('}
            Program’s Market Share/Largest Market Share in the Same Context
            {')'}
          </Typography>
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
            Program’s Market Share = {' '}
            {'('}
            Enrollment of the Program/Total Enrollment of All Programs
            {')'}
          </Typography>
        </Box></React.Fragment>}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </NoMaxWidthTooltip>
      </Box>
      </div></>


    </div>
  )
}
