import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import Typography from "../../components/Typography";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

import WB1 from '../../assets/images/E-WEB-Goal-01.png';
import WB2 from '../../assets/images/E-WEB-Goal-02.png';
import WB3 from '../../assets/images/E-WEB-Goal-03.png';
import WB4 from '../../assets/images/E-WEB-Goal-04.png';
import WB5 from '../../assets/images/E-WEB-Goal-05.png';
import WB6 from '../../assets/images/E-WEB-Goal-06.png';
import WB7 from '../../assets/images/E-WEB-Goal-07.png';
import WB8 from '../../assets/images/E-WEB-Goal-08.png';
import WB9 from '../../assets/images/E-WEB-Goal-09.png';
import WB10 from '../../assets/images/E-WEB-Goal-10.png';
import WB11 from '../../assets/images/E-WEB-Goal-11.png';
import WB12 from '../../assets/images/E-WEB-Goal-12.png';
import WB13 from '../../assets/images/E-WEB-Goal-13.png';
import WB14 from '../../assets/images/E-WEB-Goal-14.png';
import WB15 from '../../assets/images/E-WEB-Goal-15.png';
import WB16 from '../../assets/images/E-WEB-Goal-16.png';
import WB17 from '../../assets/images/E-WEB-Goal-17.png';
export default function BuildingCapacity() {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const WBIconStyle = {
    // padding: '30px'
    maxWidth: '10em',
    width: '100%'
  }
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Typography variant="h4" marked="center" align="center" component="h2">
      SUSTAINABLE DEVELOPMENT GOALS
      </Typography>
      <div
        style={{
          paddingTop: '3vh'
        }}
      >
        <div style={{
          display: 'grid',
          gridTemplateColumns: isDesktop ? '1fr 1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
          width: '100%',
          // gap: '25px',
        }}>
          <img style={WBIconStyle} src={WB1} alt="icon-E-WEB-Goals-WB1" />
          <img style={WBIconStyle} src={WB2} alt="icon-E-WEB-Goals-WB2" />
          <img style={WBIconStyle} src={WB3} alt="icon-E-WEB-Goals-WB3" />
          <img style={WBIconStyle} src={WB4} alt="icon-E-WEB-Goals-WB4" />
          <img style={WBIconStyle} src={WB5} alt="icon-E-WEB-Goals-WB5" />
          <img style={WBIconStyle} src={WB6} alt="icon-E-WEB-Goals-WB6" />
          <img style={WBIconStyle} src={WB7} alt="icon-E-WEB-Goals-WB7" />
          <img style={WBIconStyle} src={WB8} alt="icon-E-WEB-Goals-WB8" />
          <img style={WBIconStyle} src={WB9} alt="icon-E-WEB-Goals-WB9" />
          <img style={WBIconStyle} src={WB10} alt="icon-E-WEB-Goals-WB10" />
          <img style={WBIconStyle} src={WB11} alt="icon-E-WEB-Goals-WB11" />
          <img style={WBIconStyle} src={WB12} alt="icon-E-WEB-Goals-WB12" />
          <img style={WBIconStyle} src={WB13} alt="icon-E-WEB-Goals-WB13" />
          <img style={WBIconStyle} src={WB14} alt="icon-E-WEB-Goals-WB14" />
          <img style={WBIconStyle} src={WB15} alt="icon-E-WEB-Goals-WB15" />
          <img style={WBIconStyle} src={WB16} alt="icon-E-WEB-Goals-WB16" />
          <img style={WBIconStyle} src={WB17} alt="icon-E-WEB-Goals-WB17" />
        </div>
      </div>
    </Container>
  );
}
