import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import {
  BrowserRouter, Route, Routes, Link
} from "react-router-dom";
import { useNavigate } from "react-router-dom";

function CompanyClients() {
  const navigate = useNavigate();

  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
      
    >
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
        id="client"
        onClick={()=>navigate("/foodbank")}

      >
        <Typography variant="h4" component="span">
          Client’s Portal
        </Typography>
      </Button>
      {/* <Typography variant="subtitle1" sx={{ my: 3 }}>
        The Jacobs & Cushman San Diego Food Bank
      </Typography> */}


      {/* <Link to="/foodbank"><Box
        component="img"
        src="/foodbank.png"
        alt="buoy"
        sx={{ width: 60 }}
      /></Link> */}

    </Container>
  );
}

export default CompanyClients;
