import * as React from "react";
import AppAppBar from "../views/AppAppBar";
import MainBanner from "../../src/views/MainBanner";
import withRoot from "../withRoot";
import Services from "../../src/views/Services";
import AboutUs from "../../src/views/AboutUs";
import CompanyClients from "../../src/views/CompanyClients";
import ProductHowItWorks from "../views/ProductHowItWorks";
import AppFooter from "../views/AppFooter";
import BuildingCapacity from "../views/Building Capacity";
import Products from "../views/Products";
import ProductValues from  "../views/CompanyValues"
const Landing = () => {
  const scrollTo = "services!";

  return (
    <React.Fragment>
      <AppAppBar myProp={scrollTo} />
      <MainBanner />
      <Services id="services" />
      <ProductHowItWorks  id="approach" />
      <ProductValues></ProductValues>
      {/* <BuildingCapacity/> */}
      <Products id="products"/>
      <AboutUs id="about" />
      {/* <CompanyClients id="client" /> */}
      <AppFooter />
    </React.Fragment>
  );
};
export default withRoot(Landing);
