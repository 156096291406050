import React from 'react';
import Plot from 'react-plotly.js';

const BarGraph = (props) => {
    return (
        <Plot
            data={props.data.data}
            layout={props.data.layout}
            style={{width:'100%', height:'100%',  display:'flex', justifyContent:'center'
            // borderRadius:'20px',overflow: 'hidden',
        }}
        />
    );
};

export default BarGraph;
