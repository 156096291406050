import React, { useContext } from 'react';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../../../components/AppBar";
import Toolbar from "../../../components/Toolbar";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
// import DashboardContext from '../../context';
import { useTheme } from '@mui/material/styles';



const TopNav = (props) => {
  // const context = useContext(DashboardContext);
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const myPropValue = props.myProp;
  const rightLink = {
    fontSize: 16,
    // color: "common.black",
    color: 'white',
    backgroundColor: secondaryColor.dark,
    ml: 3,
  };

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/'); // Redirect to the login route
    } catch (error) {
      console.log('Error signing out:', error);
      // Handle error, if any, during logout
    }
  };

  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <section>
      {/* <AppBar position="fixed" sx={{ backgroundColor: 'white' }} > */}
      <AppBar
        position="fixed"
        sx={{
          // backgroundColor: "white",
          display: "block",
          background: "rgba(255, 255, 255, 0.9)",
          boxShadow: "0 5px 30px 0 rgba(0,0,0,0.1)",
          padding: "0.5em 0em 0.1em 0em",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ flex: 1, display: "flex" }}>
            <Link
              variant="h6"
              underline="none"
              color={secondaryColor.dark}
              sx={{ fontSize: 24, display: "flex" }}
              href="/"
            >
              <img
                src="/sindex_logo.png"
                alt="Sindex logo"
                // maxWidth="10%"
                // style={{ width: "40vh", height: "10vh" }}
                style={{ display: "flex", maxWidth: "22%", minWidth: "1em" }}
              />
            </Link>
          </Box>
          {isDesktop ? (<></>
            // <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            //   <Button
            //     // color="secondary"
            //     variant="contained"
            //     onClick={handleLogout}
            //     // variant="h6"
            //     // underline="none"
            //     sx={rightLink}
            //   >
            //     {"LOGOUT"}
            //   </Button>
            // </Box>
          ) : (
            <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
              <img alt="menu-icon" src="/menu-icon.png" />
            </button>
          )}

          {!isDesktop && isMenuToggled && (
            <div
              style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                height: "100%",
                width: "300px",
                flexDirection: "column",
                display: "block",
                opacity: "1",
                backgroundColor: "#FFF7ED",
              }}
            >
              {/* Content of the new div */}
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                  <img alt="close-icon" src="/close.png" />
                </button>
              </div>

              {/* <div style={{ marginTop: "2rem" }}>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    color="primary"
                    href="#services"
                    variant="h6"
                    underline="none"
                    sx={rightLink}
                  >
                    {"LOGOUT"}
                  </Link>
                </div>
              </div> */}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </section>
  );
};

export default TopNav;
