import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

const PLOCard = ({ program }) => {
  const data = [
    {
      name: "BSBA",
      PLOs: [
        {
          id: 1,
          title: "Content Knowledge",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Foundational Knowledge",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Concentration-Specific Knowledge",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Experiential Learning",
            },
          ],
        },
        {
          id: 2,
          title: "Information-Driven Decision Makers",
          subPLOs: [
            {
              title: "Critical Thinking Competency",
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `Trait 1: Facts VS Opinion - 49% 
                        Trait 2: Logic - 33%
                        Trait 3: Context - 93%
                        Trait 4: Sophistication - 98%
                        Trait 5: Use other perspective - 96%
                        Trait 6: Use of sources - 87%`,
            },
            {
              title: "Technology-based Analytics Competency",
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `Trait 1 - Issue Identification - 99%
                        Trait 2 - Tools for Analysis - 99%
                        Trait 3 - Significance - 94%
                        Trait 4 - Clean Data - 98%
                        Trait 5 - Model Selection - 92%
                        Trait 6 - Execution - 99%
                        Trait 7 - Tools for Communication -78%
                        Trait 8 - Communication - 80%
                        Trait 9 - Accuracy -100%`,
            },
          ],
        },
        {
          id: 3,
          title: "Communication",
          subPLOs: [
            {
              title: "Oral Communication Competency",
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `Trait 1 - Language - 100%
                        Trait 2 - Eye Contact - 65%
                        Trait 3 - Body Language - 84%
                        Trait 4 - Elocution - 94%
                        Trait 5 - Time Management - 71%
                        Trait 6 - Visuals - 87%
                        Trait 7 - Organization - 100%`,
            },
            {
              title: "Written Communication Competency",
              goals:
                "At least 75% of students will meet or exceed expectations",
            },
          ],
        },
        {
          id: 4,
          title: "Community Citizen",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Sustainability Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Ethics Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              title: "Diversity Competency",
            },
          ],
        },
      ],
    },
    {
      name: "BSBA Online",
      PLOs: [
        {
          id: 1,
          title: "Content Knowledge",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Foundational Knowledge",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Concentration-Specific Knowledge",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Experiential Learning",
            },
          ],
        },
        {
          id: 2,
          title: "Information-Driven Decision Makers",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `"Trait 1: Facts VS Opinion - 98%
Trait 2: Logic - 73%
Trait 3: Context - 98%
Trait 4: Sophistication - 98%
Trait 5: Use other perspective - 95%
Trait 6: Use of sources - 91%"`,
              title: "Critical Thinking Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `"Trait 1 - Issue Identification - 100%
Trait 2 - Tools for Analysis - 97%
Trait 3 - Significance - 95%
Trait 4 - Clean Data - 95%
Trait 5 - Model Selection - 77%
Trait 6 - Execution - 98%
Trait 7 - Tools for Communication -74%
Trait 8 - Communication - 67%
Trait 9 - Accuracy -100%"`,
              title: "Technology-based Analytics Competency",
            },
          ],
        },
        {
          id: 3,
          title: "Communication",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `"Trait 1 - Language - 93%
Trait 2 - Eye Contact - 64%
Trait 3 - Body Language - 86%
Trait 4 - Elocution - 86%
Trait 5 - Time Management - 93%
Trait 6 - Visuals - 86%
Trait 7 - Organization - 82% "`,
              title: "Oral Communication Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Written Communication Competency",
            },
          ],
        },
        {
          id: 4,
          title: "Community Citizen",
          subPLOs: [
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Sustainability Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: `"Trait 1: Identify Values/Principles - 79%
Trait 2: Recongize Relevant Ethical Issues - 79% 
Trait 3: Bias/Situational Factors - 95%
Trait 4: Stakeholders - 95%
Trait 5: Application - 87%
Trait 6: Consequences - 92%"`,
              title: "Ethics Competency",
            },
            {
              goals:
                "At least 75% of students will meet or exceed expectations",
              results: "",
              title: "Diversity Competency",
            },
          ],
        },
      ],
    },
    {
      name: "MBA with Concentrations",
      PLOs: [
        {
          id: 1,
          title:
            "Identify global business opportunities, analyze global business challenges, and develop business strategies.",
        },
        {
          id: 2,
          title: "Demonstrate leadership and teamwork skills.",
        },
        {
          id: 3,
          title: "Apply advanced written communication skills.",
        },
        {
          id: 4,
          title: "Apply advanced oral communication skills.",
        },
        {
          id: 5,
          title:
            "Demonstrate cross-cultural awareness and sensitivity in their interpersonal and group communication.",
        },
        {
          id: 6,
          title:
            "Perform quantitative analyses and apply advanced technological tools to solve complex business problems.",
        },
        {
          id: 7,
          title:
            "Analyze and integrate knowledge across disciplines to make managerial decisions to reach solutions to complex business problems.",
        },
        {
          id: 8,
          title: "Recognize and analyze ethical issues in decision making.",
        },
      ],
    },
    {
      name: "MBA Innovators",
      PLOs: [1, 2, 3],
    },
    {
      name: "MSBA",
      PLOs: [
        {
            goals:"85%",
            results:"Not Met",
            title:"Technology Expertise: Students who graduate will be effective users of technologies for decision-making."},
        {
            goals:"85%",
            results:"Met",
            title:"Quantitative Methods: Students who graduate will be knowledgeable in quantitative methods and tools of business analytics."},
        {
            goals:"85%",
            results:"Met",
            title:"Business Decision Making: Students who graduate will be knowledgeable in business analytics and in integrating it with various business functions."},
        {
            goals:"85%",
            results:"Met",
            title:"Written Communication: Students who graduate will be effective communicators of business analytics projects."},
      ],
    },
    {
      name: "BSE",
      PLOs: [
        {
          id: 1,
          title:
            "Graduates will have the requisite knowledge to solve economic problems",
          subPLOs: [
            "1A: Graduates will formulate mathematical models to solve microeconomic problems",
            "1B: Graduates will formulate mathematical models to solve macroeconomic problems",
          ],
        },
        {
          id: 2,
          title:
            "Graduates will be competent in the use of modern statistical packages to analyze data",
          subPLOs: [
            "2A: Graduates will be proficient in a programming language to analyze data and conduct economic research",
          ],
        },
        {
          id: 3,
          title: "Graduates will be effective communicators",
          subPLOs: [
            "3A: Graduates will communicate coherent economic policy arguments, grounded in economic theory.",
          ],
        },
      ],
    },
    {
      name: "MSA",
      PLOs: [
        {
          id: 1,
          title: "Content Knowledge",
          subPLOs: ["Financial reporting", "Auditing", "Tax"],
        },
        {
          id: 2,
          title: "Critical Thinking and Analytic Skills",
          subPLOs: ["2A Critical Thinking", "2B Technology-based Analytics"],
        },
        {
          id: 3,
          title: "Communication Skills",
          subPLOs: ["3A Oral Communication", "3B Written Communication"],
        },
        {
          id: 4,
          title: "Interpersonal Competence",
          subPLOs: ["4A Teamwork", "4B Ethics"],
        },
      ],
    },
    {
      name: "BS BAN",
      PLOs: [
        {
          id: 1,
          title: "Foundational Knowledge",
          subPLOs: [
            "1A: Quantitative foundational knowledge",
            "1B: Technology foundational knowledge competency",
          ],
        },
        {
          id: 2,
          title: "Information-Driven Decision Makers",
          subPLOs: [
            "2A: Analytical modeling competency",
            "2B: Technology-based competency for decision-making",
          ],
        },
        {
          id: 3,
          title: "Communication",
          subPLOs: ["3A: Written communication competency"],
        },
        {
          id: 4,
          title: "Sustainability, diversity, and Equality",
          subPLOs: [
            "4A: Improve sustainability",
            "4B: Improve diversity, social justice, and equality",
          ],
        },
      ],
    },
    {
      name: "BSAA",
      PLOs: [
        {
          id: 1,
          title: "Core Knowledge",
          subPLOs: [
            "1A: Auditing and Attestation",
            "1B: Financial Accounting and Reporting",
            "1C: Taxation and Regulation",
            "1D: Data Analytics",
          ],
        },
        {
          id: 2,
          title: "Effective Communication",
        },
        {
          id: 3,
          title: "Ethical Principles and Professional Standards",
        },
      ],
    },
    {
      name: "MS MAnalytics",
      PLOs: [
        "PLO 1 Technology Expertise: Demonstrate advanced knowledge and skills in using technologies for marketing analytics and application.",
        "PLO 2 Quantitative Methods: Build expertise in quantitative methods and tools for marketing analytics.",
        "PLO 3 Marketing Decision Making: Apply data analytics skills in making effective marketing decisions.",
        "PLO 4 Written and Verbal Communication: Apply effective written and verbal communication skills in conveying project ideas, activities, and findings.",
      ],
    },
  ];

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        {/* <Typography variant="h5" component="div" gutterBottom>
          {program}
        </Typography> */}
        <List>
          {data.map(
            (prog) =>
              prog.name === program && (
                <>
                  {prog.PLOs.map((plo, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                      >
                        <Typography variant="subtitle1">{plo.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {plo.subPLOs &&
                            plo.subPLOs.map((subPLO, subIndex) => (
                              <ListItem key={subIndex} sx={{ pl: 4 }}>
                                <ListItemText primary={subPLO.title} />
                                <Tooltip
                                  title={subPLO.goals}
                                  placement="top-start"
                                >
                                  <Button>Goal</Button>
                                </Tooltip>
                                <Tooltip
                                  title={subPLO.results}
                                  placement="top-start"
                                >
                                  <Button>Result</Button>
                                </Tooltip>
                              </ListItem>
                            ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default PLOCard;
