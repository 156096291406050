import * as React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Container component="section" sx={{ pt: 8, pb: 4 }}>
      {/* <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light', }}
      > */}
      {/* <Box className="MuiBox-root css-0" sx={{ width: '100%' }}> */}
      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap', gap: "1em" }}>
        <Card raised={true} sx={{ background: "linear-gradient(150deg, #FFF7ED, #B28D86 150%)", maxWidth: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" >
                YOU COMMIT
              </Typography>
              <Typography variant="h5" color="text.secondary" >
                to a current and relevant curriculum for student success.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card raised={true} sx={{
          background: "linear-gradient(150deg, #FFF7ED, #B28D86 150%)", maxWidth: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em',
        }}>
          <CardActionArea >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                YOU FORM
              </Typography>
              <Typography variant="h5" color="text.secondary">
                a task force to review your program
                portfolio and generate a list of
                recommendations to optimize your
                curriculum.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card raised={true} sx={{ background: "linear-gradient(150deg, #FFF7ED, #B28D86 150%)", maxWidth: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                NOW WHAT?
              </Typography>
              <Typography variant="h5" color="text.secondary">
                many universities and colleges face a
                challenge to design and implement a
                plan to address the gap between the
                current and optimum program portfolio.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Box sx={{ display: "flex", flexWrap: 'wrap',  justifyContent: "space-between", alignItems: 'center' }}>
        <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '15em', maxHeight: '15em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                1
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                USE the BCG matrix
              </Typography>
              <Typography variant="h5" color="text.secondary">
                to identify the potential intervention.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <img
          src="/heds_logo_full.png"
          alt="Brave Spaces"
          style={{ display: "flex", justifyContent: "space-between", maxWidth: "30%", minWidth: "5em" }}
        />
        <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em', maxHeight: '15em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                2
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                VERIFY
              </Typography>
              <Typography variant="h5" color="text.secondary">
                the identification with additional
                data/information from program experts.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", gap: "1em" }}>
        {/* <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                1
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                USE the BCG matrix
              </Typography>
              <Typography variant="h5" color="text.secondary">
                to identify the potential intervention.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> */}
        {/* <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                2
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                VERIFY
              </Typography>
              <Typography variant="h5" color="text.secondary">
                the identification with additional
                data/information from program experts.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> */}
        <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                3
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                SYSTEMATICALLY
              </Typography>
              <Typography variant="h5" color="text.secondary">
                build precise interventions to address
                the challenge.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                4
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                TRANSPARENTLY
              </Typography>
              <Typography variant="h5" color="text.secondary">
                include faculty, staff, and students in
                the intervention.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card raised={true} sx={{ backgroundColor: "#FFF7ED", width: 345, padding: "0.5em", margin: "0.5em", minHeight: '8em' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h2" component="div">
                5
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                MEASURE
              </Typography>
              <Typography variant="h5" color="text.secondary">
                the impacts of the intervention.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>

      {/* </Box> */}
      {/* <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src="/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/vision.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Our Vision
              </Typography>
              <Typography variant="h5">
                {
                  'To create a world where diversity, inclusion, and core values are embraced, celebrated,'
                }

                {
                  ', and integrated into every aspect of society, fostering a sense of belonging and equality for all.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/mission.svg"
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Our Mission
              </Typography>
              <Typography variant="h5">
                {
                  'We strive to create inclusive environments that value diverse perspectives, nurture collaboration, '
                }

                {'and empower individuals to reach their full potential.'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/values.svg"
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Core Values
              </Typography>
              <Typography variant="h5">
                {'Respect, Collaborate, Empower'}
                {' by upholding these values, we aim to create a more inclusive and equitable world, where diversity is not only accepted but embraced as a fundamental strength of our global community.'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container> */}
      {/* </Box> */}
    </Container>
  );
}

export default ProductValues;
