import * as React from "react";
import AppAppBar from "../views/TopNavigation";
import MainBanner from "../views/MainBanner";
// import withRoot from "../withRoot";
import Services from "../views/Services";
import AboutUs from "../views/AboutUs";
import CompanyClients from "../views/CompanyClients";
// import ProductHowItWorks from "../views/ProductHowItWorks";
import AppFooter from "../views/AppFooter";
import UniqueApproach from "../views/UniqueApproach";
// import Products from "../views/Products";
import BuildingCapacity from "../views/Building Capacity"
const SindexLanding = () => {
//   const scrollTo = "services!";

  return (
    <React.Fragment>
      <AppAppBar/>
      <MainBanner />
      <Services id="services" />
      <UniqueApproach  id="approach" />
      <BuildingCapacity/>
      {/* <Products id="products"/> */}
      <AboutUs id="about" />
      <CompanyClients id="client" />
      <AppFooter />
    </React.Fragment>
  );
};
export default SindexLanding;
