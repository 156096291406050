export const RetentionRate_MBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70],
            y: [75, 87.5, 76.9, 100, 85.7, 80, 100],
            name: '1 Year Retention Rate (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70],
            y: [45, 37.5, 84.6, 33.3, 78.6, 26.7, 20],
            name: '2 Year Retention Rate (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70],
            y: [5, 16.7, 38.5, 0, 7.1, 6.7, 0],
            name: '3 Year Retention Rate (State Support)',
        }
    ],
    layout: {
        title: 'Retention Rates for MBA (State Support Programs)',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            automargin: true,
            title: '',
            tickvals: [10, 20, 30, 40, 50, 60, 70],
            ticktext: ['Other MBA',
                // 'Analytics for managers/Analytics for managers MBA',
                'Finance',
                'HRM&OB/HR&OB',
                'Marketing management',
                'OSCM',
                'ITM',
                // 'General business',
                'Strategy and innovation'],
            tickangle: -45
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}



export const oneYearRetentionRate_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [81.5, 76.0, 50, 73.3, 73.5, 70, 100, 69.1, 100, 0], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [71.9, 90.4, 100, 82.9, 86.7, 87.3, 91.5, 91.7, 78.3, 82.4], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '1 Year Retention Rate: BS',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const twoYearRetentionRate_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [55.6, 60, 50, 60, 73.5, 60, 100, 54.5, 57.1, 0], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 50, 50, 60, 70, 80, 90, 100],
            y: [28.1, 42.1, 100, 32.9, 47.6, 46.8, 54.8, 91.7, 52.2, 64.7], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '2 Year Retention Rate: BS',
        // paper_bgcolor:'#28282a',

        autotickangles: true,
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 200] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const threeYearRetentionRate_BSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [63, 60, 50, 60, 55.9, 60, 50, 52.7, 57.1, 0], // Transfer students percentage
            name: 'First Time Freshman (State Support)',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [6.3, 7.9, 0, 4.3, 11.2, 23.8, 12.8, 17.9, 17.4, 41.2], // Transfer students percentage
            name: 'First Time Transfer (State Support)',
        }
    ],
    layout: {
        title: '3 Year Retention Rate: BS',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 100] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}



export const oneYearRetentionRate_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [0, 50, 0, 82.6, 64.5, 0, 62.5, 100, 81.3, 80], // Transfer students percentage
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '1 Year Retention Rate: BSBA Online',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const twoYearRetentionRate_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [0, 100, 0, 34.8, 37.6, 0, 18.8, 0, 37.5, 40], // Transfer students percentage
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '2 Year Retention Rate: BSBA Online',
        // paper_bgcolor:'#28282a',

        autotickangles: true,
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}
export const threeYearRetentionRate_BSBA_Online = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            y: [0, 100, 0, 8.7, 20.4, 0, 6.3, 0, 18.8, 10], // Transfer students percentage
            name: 'First Time Transfer (Self Support)',
        }
    ],
    layout: {
        title: '3 Year Retention Rate: BSBA Online',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '',
            automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            ticktext: ['Entrepreneurship',
                'Acounting',
                'Business Economics',
                'Finance',
                'General Management',
                'HRM/OB',
                'ITM',
                'Marketing Management',
                'BS without Concentration(s)',
                'OSCM',], tickangle: -30
        },
        yaxis: { title: 'Percentage of Students', range: [0, 150] }, // Adjust the range based on your data
        barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        // width: 600,
        // height: 400,
    }
}



export const enrolementOverTime_MBA = {
    data: [
        {
            "type": "line",
            "name": "MBA: no concentration",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [44, 26, 35, 26, 16, 8]
        },
        {
            "type": "line",
            "name": "MBA: Analytics for managers",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [3, 13, 26, 27, 23, 21]
        },
        {
            "type": "line",
            "name": "MBA: Finance",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [55, 43, 35, 37, 29, 19]
        },
        {
            "type": "line",
            "name": "MBA: General business",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [9, 25, 32, 45, 34, 40]
        },
        {
            "type": "line",
            "name": "HRM&OB/HR&OB",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [27, 25, 30, 49, 45, 32]
        },
        {
            "type": "line",
            "name": "ITM",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [11, 3, null, null, null, null]
        },
        {
            "type": "line",
            "name": "Marketing management",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [17, 14, 14, 20, 29, 28]
        },
        {
            "type": "line",
            "name": "OSCM",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [25, 31, 24, 23, 24, 14]
        },
        {
            "type": "line",
            "name": "Strategy and innovation",
            "x": [2018, 2019, 2020, 2021, 2022, 2023],
            "y": [14, 18, 27, 24, 22, 19]
        }
    ],
    layout: {
        title: 'Enrollment (MBA)',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: 'Year',
            automargin: true,
            tickvals: ['2018', '2019', '2020', '2021', '2022', '2023'], // Set the tick values to the years
            ticktext: ['2018', '2019', '2020', '2021', '2022', '2023'],
        },
        yaxis: { title: 'Number of Students', range: [0, 60] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        width: 700,
        // height: 1000,
    }
}

export const enrolementOverTime_BSBA = {
    data: [
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [602, 545, 544, 521, 481, 468],
            name: 'BSBA without Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [476, 443, 421, 432, 429, 463],
            name: 'BSBA: Accounting',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [45, 44, 55, 48, 31, 23],
            name: 'Business economics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [8, 9, 6, 8, 4, 2],
            name: 'Corporate management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [148, 132, 128, 117, 114, 116],
            name: 'Entrepreneurship',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [326, 397, 402, 391, 348, 309],
            name: 'Finance',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [575, 656, 684, 627, 573, 558],
            name: 'General business/General Management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [184, 205, 216, 220, 208, 247],
            name: 'HRM&OB/HRM',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [212, 231, 237, 235, 210, 244],
            name: 'ITM',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [385, 395, 405, 393, 354, 351],
            name: 'Marketing management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [76, 85, 92, 113, 119, 115],
            name: 'OSCM',
        }
    ],
    layout: {
        title: 'Enrollment (BSBA)',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: 'Year',
            automargin: true,
            tickvals: ['2018', '2019', '2020', '2021', '2022', '2023'], // Set the tick values to the years
            ticktext: ['2018', '2019', '2020', '2021', '2022', '2023'],
        },
        yaxis: { title: 'Number of Students', range: [0, 800] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        width: 700,
        // height: 1000,
    }
}

export const enrolementOverTime_BSBA_Online = {
    data: [
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [79, 36, 33, 10, 3, 1],
            name: 'BSBA Online without Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [2, 3, 30, 99, 180, 227],
            name: 'Accounting',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [1, null, null, null, null, null], // Business economics
            name: 'Business economics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [3, 6, 5, 7, 3, 2],
            name: 'Corporate management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, null, 1, null, 1], // Entrepreneurship
            name: 'Entrepreneurship',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [91, 143, 155, 156, 139, 121],
            name: 'Finance',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [196, 235, 255, 249, 244, 211],
            name: 'General business/General Management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, 19, 67, 82, 130], // HRM&OB/HRM
            name: 'HRM&OB/HRM',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [50, 68, 78, 108, 91, 110],
            name: 'ITM',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [2, 1, null, null, 4, 34], // Marketing management
            name: 'Marketing management',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [34, 42, 48, 67, 69, 69],
            name: 'OSCM',
        },
    ],
    layout: {
        title: 'Enrollment (BSBA Online)',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: 'Year',
            automargin: true,
            tickvals: ['2018', '2019', '2020', '2021', '2022', '2023'], // Set the tick values to the years
            ticktext: ['2018', '2019', '2020', '2021', '2022', '2023'],
        },
        yaxis: { title: 'Number of Students', range: [0, 300] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        width: 700,
        // height: 1000,
    }
}

export const enrolementOverTime_Top = {
    data: [
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [205, 198, 223, 251, 222, 181],
            name: 'MBA with Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [2576, 2662, 2693, 2599, 2398, 2430],
            name: 'BSBA with Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [458, 534, 623, 768, 845, 934],
            name: 'BSBA Online with Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [94, 99, 81, 76, 60, 60],
            name: 'Executive MBA for Global Innovators',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, null, null, null, null], // One-Year Intensive MBA
            name: 'One-Year Intensive MBA',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, null, null, null, null], // BS Business Analytics with Concentration(s)
            name: 'BS Business Analytics with Concentration(s)',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, null, null, null, null], // BS Accounting and Analytics
            name: 'BS Accounting and Analytics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [89, 78, 87, 69, 49, 49],
            name: 'BS Economics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [148, 172, 169, 170, 289, 305],
            name: 'MS Business Analytics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [30, 46, 59, 58, 49, 40],
            name: 'MS Accountancy',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [38, 25, 21, 19, 13, null], // MS Quantitative Economics
            name: 'MS Quantitative Economics',
        },
        {
            type: 'line',
            x: ['2018', '2019', '2020', '2021', '2022', '2023'],
            y: [null, null, null, null, null, null], // MS Marketing Analytics
            name: 'MS Marketing Analytics',
        },
    ],
    layout: {
        title: 'Enrollment (All Programs)',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: 'Year',
            automargin: true,
            tickvals: ['2018', '2019', '2020', '2021', '2022', '2023'], // Set the tick values to the years
            ticktext: ['2018', '2019', '2020', '2021', '2022', '2023'],
        },
        yaxis: { title: 'Number of Students', range: [0, 3000] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        // yaxis: {
        //     title: 'Number of Students',
        //     range: [0, 3000], // Adjust the range based on your data
        //     tickvals: [0, 500, 1000, 1500, 2000, 2500, 3000], // Custom tick values
        //     ticktext: ['0', '500', '1k', '1.5k', '2k', '2.5k', '3k'], // Custom tick text
        // },
        legend: { orientation: 'v', itemclick: "toggleothers" }, // Horizontal legend
        autosize: true,
        width: 700,
        // height: 300,
    }
}