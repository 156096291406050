import * as React from "react";
import AppAppBar from "./views/AppAppBar";
import MainBanner from "../src/views/MainBanner";
import withRoot from "./withRoot";
import CompanyValues from "../src/views/CompanyValues";
import Services from "../src/views/Services";
import AboutUs from "../src/views/AboutUs";
import CompanyClients from "../src/views/CompanyClients";
import ProductHowItWorks from "./views/ProductHowItWorks";
import AppFooter from "./views/AppFooter";
import SignIn from "./components/SignIn";
import Landing from "./components/Landing";
import Terms from "./Terms";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Programmatics/pages/Dashboard";
import SindexDashboard from "./Sindex/ClientApp/pages/Dashboard";
import Blueprint from "./Programmatics/pages/Blueprint";
import SindexBlueprint from "./Sindex/ClientApp/pages/Blueprint";
import Tracker from "./Programmatics/pages/Tracker";
import SindexTracker from "./Sindex/ClientApp/pages/Tracker";
import Survey from "./Programmatics/pages/Survey";
import UploadData from "./Programmatics/pages/UploadData";
import SindexUploadData from "./Sindex/ClientApp/pages/UploadData";
import FullFeaturedCrudGrid from "./Programmatics/pages/DataGrid"
import '@aws-amplify/ui-react/styles.css';
// import Pipeline from "./Programmatics/CensusPipeline/Pipeline";
import { createContext, useState, useEffect } from "react";
import DashboardContext from './context';
import axios from 'axios';
import Sidenav from "./Programmatics/pages/Sidenav";
import SindexSidenav from "./Sindex/ClientApp/pages/Sidenav";
import ProgramOptimization from "./Programmatics/pages/ProgramOptimization";
import SindexProgramOptimization from "./Sindex/ClientApp/pages/ProgramOptimization";
import SindexLanding from './Sindex/pages/Landing'


const App = () => {
  // const theme = createTheme();
  const scrollTo = "services!";
  const [loading, setLoading] = useState(true);


  useEffect(() => {
  }, []);

  return (
    <React.Fragment>
      <DashboardContext.Provider value={{}}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/programmatics" element={<Sidenav />}>
            <Route index element={<Dashboard />} />
            <Route path="/programmatics/blueprint" element={<Blueprint />} />
            <Route path="/programmatics/tracker" element={<Tracker />} />
            <Route path="/programmatics/portfolioOptimization" element={<ProgramOptimization />} />
            <Route path="/programmatics/upload" element={<UploadData />} />
            {/* <Route path="/" element={<Landing />} />
          <Route path="/" element={<Landing />} /> */}
          </Route>
          <Route path="/sindex" element={<SindexLanding />} />
          <Route path="/sindex/client" element={<SindexSidenav />}>
            <Route index element={<SindexDashboard />} />
            <Route path="/sindex/client/blueprint" element={<SindexBlueprint />} />
            <Route path="/sindex/client/tracker" element={<SindexTracker />} />
            <Route path="/sindex/client/portfolioOptimization" element={<SindexProgramOptimization />} />
            <Route path="/sindex/client/upload" element={<SindexUploadData />} />
            {/* <Route path="/" element={<Landing />} /> */}
          </Route>
        </Routes>
        {/* </ThemeProvider> */}
      </DashboardContext.Provider>
    </React.Fragment>
  );
};
export default withRoot(App);
