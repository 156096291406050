import React from 'react';
import Plot from 'react-plotly.js';
import { useMediaQuery } from "@mui/material";
const BCGMatrix = (props) => {
    // console.log(props);
    const isDesktop = useMediaQuery("(min-width: 768px)");
    console.log(isDesktop);
    if (props.chartData.products) {
        const plotData = props.chartData.products.map(product => ({
            type: 'scatter',
            mode: 'markers',
            x: [product.marketShare],
            y: [product.marketGrowthRate],
            marker: {
                color: getProductColor(product, props.chartData),
                size: 18,
            },
            text: [product.name],
            name: product.name,
        }));

        const layout = {
            title: 'BCG Matrix',
            xaxis: { title: props.chartData.xaxisTitle },
            yaxis: { title: props.chartData.yaxisTitle },
            hovermode: 'closest',
            shapes: props.chartData.shapes,
            autosize:true,
            // title: { font: 2 }
            width: isDesktop ? 700 : 500, 
            height: isDesktop ? 450 : 350,
        };

        return <></>;
        // return <Plot data={plotData} layout={layout} />;
    }
};

const getProductColor = (product, chartData) => {
    // You can customize the color mapping based on your preferences
    if (product.marketGrowthRate >= chartData.yThreshold && product.marketShare <= chartData.xThreshold) {
        return '#e1f0e1'; // Question Marks
    } else if (product.marketGrowthRate >= chartData.yThreshold && product.marketShare > chartData.xThreshold) {
        return '#8ea9b0'; // Stars
    } else if (product.marketGrowthRate < chartData.yThreshold && product.marketShare >= chartData.xThreshold) {
        return '#f9e9cf'; // Cash Cows
    } else {
        return '#fabfb9'; // Dogs
    }
};

export default BCGMatrix;