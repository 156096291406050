import React, { useContext, useEffect, useState, createRef } from 'react'

import Typography from "../../../components/Typography";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import DashboardContext from '../../context';
import { useTheme } from '@mui/material/styles';
// import { DataGrid } from "@mui/x-data-grid";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
export default function UploadData() {
  const [genderRowData, setGenderRowData] = useState([]);
  const [ethnicityRowData, setEthnicityRowData] = useState([]);
  const [raceRowData, setRaceRowData] = useState([]);
  const [veteranRowData, setVeteranRowData] = useState([]);
  const [disabilityRowData, setDisabilityRowData] = useState([]);
  // const [rowModesModel, setRowModesModel] = React.useState({});
  const [dataType, setDataType] = React.useState('Accountancy');
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  // const ctx = useContext(DashboardContext);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const accountancy = [
    {
      field: "gender",
      headerName: "Accountancy",
      flex: 1,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true,
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true,
    },
  ];
  const business_administration = [
    {
      field: "business_administration",
      headerName: "Quantitative Economics",
      flex: 1,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true,
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true,
    },
  ];
  const economics = [
    {
      field: "economics",
      headerName: "Economics",
      flex: 1,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true,
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true,
    },
  ];
  const veteran_columns = [
    {
      field: "Business Analytics",
      headerName: "Business Analytics",
      flex: 1,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true,
    },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true,
    },

  ];
  const disability_columns = [
    // { field: "row_id", headerName: "Row ID", flex: 1, editable: true },
    // { field: "company_id", headerName: "Company ID", flex: 1, editable: true },
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "company_or_county",
    //   headerName: "Company/County",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "is_disable",
      headerName: "Disable",
      flex: 1,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      editable: true,
    },
    // {
    //   field: "county_name",
    //   headerName: "County Name",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "county_id",
    //   headerName: "County ID",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "percent",
      headerName: "Percent",
      flex: 1,
      editable: true,
    },

  ];

  React.useEffect(() => {
  }, []);




  const saveDataByRow = async (fetchData, payload, url) => {
    // const { data } = await axios.post(url, payload
    //   , {
    //   headers: {
    //     "x-api-key": "NUQg1g8vg07dnS30ujuHi2TCGjloWdai7X9tPCy0",
    //   },
    // }
    // )
    // ;
    // fetchData();
  };

  const handleSubmit = (name, url, fetchData) => {
    // switch (name) {
    //   case "gender":
    //     genderRowData.forEach((item) => {
    //       saveDataByRow(fetchData, item, url);
    //     });
    //     break;
    //   case "ethnicity":
    //     ethnicityRowData.forEach((item) => {
    //       saveDataByRow(fetchData, item, url);
    //     });
    //     break;
    //   case "race":
    //     raceRowData.forEach((item) => {
    //       saveDataByRow(fetchData, item, url);
    //     });
    //     break;
    //   case "veteran":
    //     veteranRowData.forEach((item) => {
    //       saveDataByRow(fetchData, item, url);
    //     });
    //     break;
    //   case "disability":
    //     disabilityRowData.forEach((item) => {
    //       saveDataByRow(fetchData, item, url);
    //     });
    //     break;
    // }

  };
  const handleChange = (event) => {
    setDataType(event.target.value);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processGenderRowUpdate = (newRow) => {
    setGenderRowData(
      genderRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };
  const processEthnicityRowUpdate = (newRow) => {
    setEthnicityRowData(
      ethnicityRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processRaceRowUpdate = (newRow) => {
    setRaceRowData(
      raceRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processVeteranRowUpdate = (newRow) => {
    setVeteranRowData(
      veteranRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const processDisabilityRowUpdate = (newRow) => {
    setDisabilityRowData(
      disabilityRowData.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  return (
    <div style={{ height: "100%", width: "100%", padding: '4em', marginLeft: isDesktop ? '312px' : '0px' }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            // width: "100%",
            height: "1em",
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: "2em",
          }}
        >
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 4 }}
          >
            Upload Data
          </Typography>
        </Box>
        {/*  Accountancy */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
          <FormControl  >
            <InputLabel id="demo-simple-select-label">Data</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dataType}
              label="Age"
              onChange={handleChange}
              sx={{ backgroundColor: secondaryColor.main }}
            >
              <MenuItem value={"Accountancy"}>Accountancy</MenuItem>
              <MenuItem value={"Business Administration"}>Business Administration </MenuItem>
              <MenuItem value={"Economics"}>Economics </MenuItem>
              <MenuItem value={"Business Analytics"}>Business Analytics  </MenuItem>
              <MenuItem value={"Quantitative Economics"}>Quantitative Economics  </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {dataType === "Accountancy" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Accountancy
          </Typography>
          <DataGrid
            rows={genderRowData}
            columns={accountancy}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processGenderRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
            sx={{ backgroundColor: secondaryColor.main }}
          />
          {/* <Button
            sx={{
              backgroundColor: secondaryColor.dark,
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }}
          >
            Submit
          </Button> */}
        </Box>}
        {/*  Ethnicity */}
        {dataType === "Quantitative Economics" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Quantitative Economics
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.main }}
            rows={ethnicityRowData}
            columns={business_administration}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processEthnicityRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          {/* <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }
            }
          >
            Submit
          </Button> */}
        </Box>}
        {/*  Economics */}
        {dataType === "Economics" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Economics
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.main }}
            rows={raceRowData}
            columns={economics}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRaceRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          {/* <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }}
          >
            Submit
          </Button> */}
        </Box>}
        {dataType === "Business Administration" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Business Administration
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.main }}
            rows={veteranRowData}
            columns={veteran_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processVeteranRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          {/* <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }

            }
          >
            Submit
          </Button> */}
        </Box>}
        {dataType === "Business Analytics" && <Box>
          <Typography variant="h6" component="span" sx={{ mt: 2 }}>
            Business Analytics
          </Typography>
          <DataGrid
            sx={{ backgroundColor: secondaryColor.main }}
            rows={disabilityRowData}
            columns={disability_columns}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processDisabilityRowUpdate}
            autoHeight
            disableSelectionOnClick
            editable
          />
          {/* <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }

            }
          >
            Submit
          </Button> */}
        </Box>}
        <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
          <Button
            sx={{
              backgroundColor: "#71502f",
              color: "white",
              "&:hover": {
                color: "black",
              },
              mt: 2,
            }}
            onClick={() => { }

            }
          >
            Submit
          </Button>
        </Box>
      </Box>
    </div>
  );
}
