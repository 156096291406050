import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { useTheme } from '@mui/material/styles';

function AboutUs() {
  const backgroundDots = "/productCTAImageDots.png";
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  return (
    <><Typography sx={{ mt: 10 }} variant="h4" marked="center" align="center" component="h2">
      ABOUT US
    </Typography>
      <Container component="section" sx={{ mt: 10, mb: 10, display: "flex" }} id="about">

        <Grid container>
          <Grid item xs={12} md={8} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                bgcolor: "#FFF7ED",
                py: 4, // Reduce the vertical padding
                px: 6, // Increase the horizontal padding
                width: "100%", // Increase the width of the container
                margin: "0 auto",
              }}
            >
              <Box sx={{ maxWidth: 600 }}>
                {/* <Typography variant="h2" component="h2" gutterBottom>
                  About Us
                </Typography> */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2">
                    Higher Ed Solutions resulted from the unique challenges that universities and colleges face to design and implement inclusive excellence.
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2">
                    We understand that a  one-size-fits-all approach is often not the answer.  Moreover, generic solutions (e.g., CRM, Software) are complicated to customize for unique challenges. Hence, we analyze and build with each program, university and college in mind.
                  </Typography>
                </Box>
                <Box sx={{ }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2">
                      We plan to never let “perfect” be the enemy of “good.” Small steps build self-efficacy, leading to big impacts.
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2">
                      We work with you to address your program health.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: { md: "block", xs: "none" }, position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src="/CEOGangaramSingh.png"
              alt="CEO"
              sx={{
                position: "absolute",
                top: +50,
                left: +50,
                right: +28,
                bottom: 0,
                width: "100%",
                maxWidth: 300,
                backgroundColor: "#FFFFFF",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Container></>
  );
}

export default AboutUs;
