import React from 'react';
import {
    Box,
    Divider,
    Stack,
    ButtonBase
} from '@mui/material';
import Typography from '../../../components/Typography';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import { useNavigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useEffect, useState, useContext } from 'react';
// import { Auth, Hub } from 'aws-amplify';
// import DashboardContext from '../../context';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
function Sidenav() {
    // const context = useContext(DashboardContext);
    const [loading, setLoading] = useState(false);
    const [isMenuToggled, setIsMenuToggled] = useState(true);

    const [activeSidenavSelection, setActiveSidenavSelection] = useState("portfolio-review");
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery("(min-width: 768px)");
    // Now you can use the theme in your component
    // For example, to get the primary color:
    const primaryColor = theme.palette.primary;
    const secondaryColor = theme.palette.secondary;
    useEffect(() => {
    }, []);



    return (
        <React.Fragment>
            {/* <DashboardContext.Provider value={{ statsData, companyData, setCompanyData, censusData, genderData, ethnicityData, raceData, disableData, veteranData }}> */}
            {isDesktop && <TopNav />}
            <div style={{ display: 'flex', paddingTop: "5em" }}>
                {isDesktop && <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '312px',
                        backgroundColor: secondaryColor.dark,
                        position: 'fixed',
                    }}
                >
                    <Box sx={{ p: 3, backgroundColor: secondaryColor.dark }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundColor: secondaryColor.dark,
                                borderRadius: 1,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 2,
                                p: '12px'
                            }}
                        >
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center", flexDirection: 'column'
                            }}>
                                <Box
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {!loading && <Box
                                        component="img"
                                        // src={context.companyData.company_code === 'EI01' ? '/Academy_Logo.png' : context.companyData.company_code === 'BS01' ? '/brave_spaces_logo.png' : '/foodbank.png'}
                                        src=''
                                        alt={""}
                                        sx={{
                                            width: 60,
                                        }}
                                        /*onClick={() => { navigate(userGroup === 'education' ? '/education' : '/foodbank') }}*/
                                        onClick={() => { navigate('/programmatics') }}
                                    />}

                                </Box>

                                <Typography
                                    color="white"
                                    variant="body2"
                                    alignItems="center"
                                    fontSize='1em'
                                >
                                    {/* Cal State East Bay, */}
                                    Client
                                </Typography>
                                <Typography
                                    color="white"
                                    variant="body2"
                                    alignItems="center"
                                    fontSize='1em'
                                >
                                    {/* College of Business & Economics */}
                                </Typography>
                            </div>

                        </Box>
                    </Box>
                    <Divider sx={{ borderColor: 'primary.light' }} />
                    <Box
                        sx={{
                            flexGrow: 1,
                            px: 2,
                            py: 3,
                            backgroundColor: secondaryColor.dark,
                        }}
                    >
                        <Stack
                            component="ul"
                            spacing={0.5}
                            sx={{
                                listStyle: 'none',
                                p: 0,
                                m: 0,
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        color: 'white',
                                    }}
                                >
                                    <Box sx={{ paddingRight: '1em' }}><BarChartOutlinedIcon /></Box>
                                    Sustainability
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        paddingLeft: '1.5em',
                                        ...(activeSidenavSelection === "portfolio-review" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            // zoom: '1.1',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <Box sx={{ paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                    <ButtonBase
                                        sx={{
                                            fontSize: '0.75rem',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("portfolio-review"); navigate('/sindex/client') }}
                                    >
                                        Baseline Review
                                    </ButtonBase>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        paddingLeft: '1.5em',
                                        borderRadius: 1,
                                        ...(activeSidenavSelection === "portfolio-optimization" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <Box sx={{ paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                    <ButtonBase
                                        style={{
                                            fontSize: '0.75rem',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("portfolio-optimization"); navigate('/sindex/client/portfolioOptimization') }}
                                    >
                                        OKRs
                                    </ButtonBase>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,

                                        ...(activeSidenavSelection === "blueprint" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em',
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            // zoom: '1.1',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'white',
                                            borderRadius: 1,
                                            fontSize: '1em',
                                            ...(activeSidenavSelection === "blueprint" && {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }),
                                            '&:hover': {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }

                                        }}
                                        onClick={() => { setActiveSidenavSelection("blueprint"); navigate('/sindex/client/blueprint') }}

                                    >
                                        <Box sx={{ paddingRight: '1em' }}><SettingsSuggestOutlinedIcon /></Box>
                                        Data-Informed Intervention
                                    </ButtonBase>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em',
                                        ...(activeSidenavSelection === "success-tracker" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            color: 'white',
                                            fontSize: '1em',
                                            ...(activeSidenavSelection === "success-tracker" && {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }),
                                            '&:hover': {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }
                                        }}
                                        onClick={() => { setActiveSidenavSelection("success-tracker"); navigate('/sindex/client/tracker') }}
                                    >
                                        <Box sx={{ paddingRight: '1em' }}><AutoGraphOutlinedIcon /></Box>
                                        Impacts of Intervention
                                    </ButtonBase>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em',
                                        ...(activeSidenavSelection === "upload-data" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            color: 'white',
                                            borderRadius: 1,
                                            fontSize: '1em',
                                            ...(activeSidenavSelection === "upload-data" && {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }),
                                            '&:hover': {
                                                backgroundColor: secondaryColor.main,
                                                color: 'black',
                                                transition: 'all 0.9s',
                                            }
                                        }}
                                        onClick={() => { setActiveSidenavSelection("upload-data"); navigate('/sindex/client/upload') }}

                                    >
                                        <Box sx={{ paddingRight: '1em' }}><UploadFileIcon /></Box>
                                        Upload Data
                                    </ButtonBase>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    <Divider sx={{ borderColor: 'white' }} />
                </Box>}
                {!isDesktop && isMenuToggled && (
                    <div
                        style={{
                            position: "fixed",
                            left: 0,
                            bottom: 0,
                            height: "100%",
                            width: "300px",
                            flexDirection: "column",
                            display: "block",
                            opacity: "1",
                            backgroundColor: "#FFF7ED",
                            zIndex: 99
                        }}
                    >
                        {/* Content of the new div */}
                        <div style={{ position: "absolute", top: 10, left: 5, zIndex: 100, padding: 0 }}>
                            <button style={{ borderRadius: 5, padding: 5, display: 'flex', alignContent: 'center', justifyContent: 'ceneter', backgroundColor: secondaryColor.main }} onClick={() => setIsMenuToggled(!isMenuToggled)}>
                                {/* <img alt="close-icon" src="/close.png" /> */}
                                <ListIcon />
                            </button>
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                width: '312px',
                                backgroundColor: secondaryColor.dark,
                                position: 'fixed',
                                // display: 'block'
                            }}
                        >
                            <Box sx={{ p: 3, backgroundColor: secondaryColor.dark }}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        backgroundColor: secondaryColor.dark,
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mt: 2,
                                        p: '12px'
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center", flexDirection: 'column'
                                    }}>
                                        <Box
                                            sx={{
                                                width: 80,
                                                height: 80,
                                                borderRadius: "50%",
                                                backgroundColor: "white",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {!loading && <Box
                                                component="img"
                                                // src={context.companyData.company_code === 'EI01' ? '/Academy_Logo.png' : context.companyData.company_code === 'BS01' ? '/brave_spaces_logo.png' : '/foodbank.png'}
                                                src='/eb_logo.png'
                                                alt={""}
                                                sx={{
                                                    width: 60,
                                                }}
                                                /*onClick={() => { navigate(userGroup === 'education' ? '/education' : '/foodbank') }}*/
                                                onClick={() => { navigate('/programmatics') }}
                                            />}
                                        </Box>
                                        <Typography
                                            color="white"
                                            variant="body2"
                                            alignItems="center"
                                            fontSize='1em'
                                        >
                                            {/* Cal State East Bay, */}
                                            Client
                                        </Typography>
                                        <Typography
                                            color="white"
                                            variant="body2"
                                            alignItems="center"
                                            fontSize='1em'
                                        >
                                            {/* College of Business & Economics */}
                                        </Typography>
                                    </div>

                                </Box>
                            </Box>
                            <Divider sx={{ borderColor: 'primary.light' }} />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    px: 2,
                                    py: 3,
                                    backgroundColor: secondaryColor.dark,
                                }}
                            >
                                <Stack
                                    component="ul"
                                    spacing={0.5}
                                    sx={{
                                        listStyle: 'none',
                                        p: 0,
                                        m: 0,
                                    }}
                                >
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                color: 'white',
                                            }}
                                        >
                                            <Box sx={{ paddingRight: '1em' }}><BarChartOutlinedIcon /></Box>

                                            Sustainability
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'white',
                                                borderRadius: 1,
                                                paddingLeft: '1.5em',
                                                ...(activeSidenavSelection === "portfolio-review" && {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }),
                                                '&:hover': {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    // zoom: '1.1',
                                                    transition: 'all 0.9s',
                                                }
                                            }}
                                        >
                                            <Box sx={{ paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                            <ButtonBase
                                                sx={{
                                                    fontSize: '0.75rem',
                                                }}
                                                onClick={() => { setActiveSidenavSelection("portfolio-review"); navigate('/sindex/client/') }}
                                            >
                                                Baseline Review
                                            </ButtonBase>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'white',
                                                paddingLeft: '1.5em',
                                                borderRadius: 1,
                                                ...(activeSidenavSelection === "portfolio-optimization" && {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }),
                                                '&:hover': {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    // zoom: '1.1',
                                                    transition: 'all 0.9s',
                                                }
                                            }}
                                        >
                                            <Box sx={{ paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                            <ButtonBase
                                                style={{
                                                    fontSize: '0.75rem',
                                                }}
                                                onClick={() => { setActiveSidenavSelection("portfolio-optimization"); navigate('/sindex/client/portfolioOptimization') }}
                                            >
                                                OKRs
                                            </ButtonBase>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'white',
                                                borderRadius: 1,

                                                ...(activeSidenavSelection === "blueprint" && {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }),
                                                marginTop: '0.5em',
                                                marginBottom: '0.5em',
                                                '&:hover': {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }
                                            }}
                                        >
                                            <ButtonBase
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    borderRadius: 1,
                                                    fontSize: '1em',
                                                    ...(activeSidenavSelection === "blueprint" && {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        transition: 'all 0.9s',
                                                    }),
                                                    '&:hover': {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        transition: 'all 0.9s',
                                                    }
                                                }}
                                                onClick={() => { setActiveSidenavSelection("blueprint"); navigate('/sindex/client/blueprint') }}

                                            >
                                                <Box sx={{ paddingRight: '1em' }}><SettingsSuggestOutlinedIcon /></Box>
                                                Data-Informed Intervention
                                            </ButtonBase>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'white',
                                                borderRadius: 1,
                                                marginTop: '0.5em',
                                                marginBottom: '0.5em',
                                                ...(activeSidenavSelection === "success-tracker" && {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }),
                                                '&:hover': {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }
                                            }}
                                        >
                                            <ButtonBase
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    borderRadius: 1,
                                                    color: 'white',
                                                    fontSize: '1em',
                                                    ...(activeSidenavSelection === "success-tracker" && {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        transition: 'all 0.9s',
                                                    }),
                                                    '&:hover': {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        // zoom: '1.1',
                                                        transition: 'all 0.9s',
                                                    }
                                                }}
                                                onClick={() => { setActiveSidenavSelection("success-tracker"); navigate('/sindex/client/tracker') }}
                                            >
                                                <Box sx={{ paddingRight: '1em' }}><AutoGraphOutlinedIcon /></Box>
                                                Impacts of Intervention
                                            </ButtonBase>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'white',
                                                borderRadius: 1,
                                                marginTop: '0.5em',
                                                marginBottom: '0.5em',
                                                ...(activeSidenavSelection === "upload-data" && {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }),
                                                '&:hover': {
                                                    backgroundColor: secondaryColor.main,
                                                    color: 'black',
                                                    transition: 'all 0.9s',
                                                }
                                            }}
                                        >
                                            <ButtonBase
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    borderRadius: 1,
                                                    fontSize: '1em',
                                                    ...(activeSidenavSelection === "upload-data" && {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        transition: 'all 0.9s',
                                                    }),
                                                    '&:hover': {
                                                        backgroundColor: secondaryColor.main,
                                                        color: 'black',
                                                        transition: 'all 0.9s',
                                                    }
                                                }}
                                                onClick={() => { setActiveSidenavSelection("upload-data"); navigate('/sindex/client/upload') }}
                                            >
                                                <Box sx={{ paddingRight: '1em' }}><UploadFileIcon /></Box>
                                                Upload Data
                                            </ButtonBase>
                                        </Box>
                                    </Box>

                                </Stack>
                            </Box>
                            <Divider sx={{ borderColor: 'white' }} />
                        </Box>
                    </div>
                )}
                {!isDesktop && !isMenuToggled && <div style={{ position: "absolute", top: 10, left: 10, zIndex: 100, padding: 0 }}>
                    <button style={{ borderRadius: 5, padding: 5, display: 'flex', alignContent: 'center', justifyContent: 'ceneter', backgroundColor: secondaryColor.main }} onClick={() => setIsMenuToggled(!isMenuToggled)}>
                        <ListIcon />
                    </button>
                </div>}
                <Outlet />
            </div>
        </React.Fragment >
    )
}
// export default withAuthenticator(Sidenav)
export default Sidenav
