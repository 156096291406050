import React, { useContext, useEffect, useState, createRef } from 'react'
import Typography from '../../components/Typography';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BCGMatrix from './BCGMatrix';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LineChart from '../Sections/ProgramOptimization_graduation';
import BarAndLineGraph from '../Sections/ProgramOptimization_retention'
import { useMediaQuery } from "@mui/material";
export default function Dashboard() {
  const theme = useTheme();
  const primaryColor = theme.palette.primary;
  const secondaryColor = theme.palette.secondary;
  const [chartType, setChartType] = React.useState('program_success');
  const ref = createRef(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [chartData, setChartData] = React.useState({
    xaxisTitle: 'Relative Market Share (%)',
    yaxisTitle: 'Market Growth Rate (%)',
    yThreshold: 0,
    xThreshold: 0.5,
    products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
    { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: 20.0 },
    { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
    { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
    { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
    ],
    shapes: [
      // Add a line parallel to the x-axis
      {
        type: 'line',
        x0: 0.5, // Adjust this value to set the position of the line
        x1: 0.5, // Adjust this value to set the position of the line
        y0: -50,
        y1: 100, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
      // Add a line parallel to the y-axis
      {
        type: 'line',
        x0: 0,
        x1: 1, // Adjust this value to set the position of the line
        y0: 0, // Adjust this value to set the position of the line
        y1: 0, // Adjust this value to set the position of the line
        line: {
          color: 'black',
          width: 2,
        },
      },
    ],
  });

  const changeChartData = (event) => {
    let type = event.target.value;

    setChartType(event.target.value);
    switch (type) {
      case "program_success":
        setChartData({
          xaxisTitle: 'Relative Market Share (%)',
          yaxisTitle: 'Market Growth Rate (%)',
          yThreshold: 0,
          xThreshold: 0.5,
          products: [{ name: 'Accountancy', marketShare: 0.012, marketGrowthRate: -22.5 },
          { name: 'Business Administration', marketShare: 0.87, marketGrowthRate: 20.0 },
          { name: 'Business Analytics', marketShare: 0.098, marketGrowthRate: 27.6 },
          { name: 'Economics', marketShare: 0.016, marketGrowthRate: -20.96 },
          { name: 'Quantitative Economics', marketShare: 0.0011, marketGrowthRate: 100 },
          ],
          shapes: [
            // Add a line parallel to the x-axis
            {
              type: 'line',
              x0: 0.5, // Adjust this value to set the position of the line
              x1: 0.5, // Adjust this value to set the position of the line
              y0: -50,
              y1: 100, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
            // Add a line parallel to the y-axis
            {
              type: 'line',
              x0: 0,
              x1: 1, // Adjust this value to set the position of the line
              y0: 0, // Adjust this value to set the position of the line
              y1: 0, // Adjust this value to set the position of the line
              line: {
                color: 'black',
                width: 2,
              },
            },
          ],
        })
        break;
      default:
    }
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', padding: '4em', width: '100%',
      // marginLeft: isDesktop ? '312px' : '0px',
      flexDirection: 'column'
    }}>
      <Box sx={{
        width: '100%',
        height: '1em',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // mt: '4em'
      }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Program Optimization - Goals
        </Typography>
      </Box>
      <Typography variant="h6" marked="center" component="caption" sx={{ mb: 4 }}>
        The Goal is to move <span style={{ color: '#f1e15b' }}>cash cows</span> to <span style={{ color: '#61cdbb' }}>stars</span>
      </Typography>
      <Typography variant="h5" marked="center" component="caption" sx={{ mb: 4 }}>
        Target Program <span style={{ color: '#f1e15b' }}>Business Administration</span>
      </Typography>

      <Box sx={{ minWidth: 120, display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
        <FormControl  >
          <InputLabel id="demo-simple-select-label">Select</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chartType}
            label="Age"
            onChange={changeChartData}
            sx={{ backgroundColor: secondaryColor.main }}
          >
            {/* <MenuItem value={"retention"}>Retention</MenuItem> */}
            <MenuItem value={"student_success"}>Student Success </MenuItem>
            <MenuItem value={"program_success"}>Program Success </MenuItem>
            {/* <MenuItem value={"growth"}>Growth  </MenuItem> */}
          </Select>
        </FormControl>
      </Box>

      <><div ref={ref} style={{
        height: '100%',
        display: 'flex'
      }}><Box sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        borderRadius: '5px',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor:secondaryColor
        alignItems: 'center',
        flexDirection: 'column',
        margin: '1em 0em 1em 0em'
      }}>
          {chartType === "program_success" && <>
            <Box sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}><BCGMatrix chartData={chartData} /></Box></>}
          {chartType === "program_success" && <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '1.2em',
            gap: '1rem'
          }}>
            <div style={{ backgroundColor: '#8ea9b0', width: '20px', height: '20px' }}></div>
            <div>Stars</div>
            <div style={{ backgroundColor: '#e1f0e1', width: '20px', height: '20px' }}></div>
            <div>Question Marks</div>
            <div style={{ backgroundColor: '#f9e9cf', width: '20px', height: '20px' }}></div>
            <div>Cash Cows</div>
            <div style={{ backgroundColor: '#fabfb9', width: '20px', height: '20px' }}></div>
            <div>Dogs</div>
          </div>}
          {chartType === "student_success" && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',  // Display items in a row
              justifyContent: 'center',
              alignItems: "center",  // Add space between the charts
              width: '100%',  // Adjust the total width as needed
              height: '100%',
            }}>
              <Box sx={{
                width: '48%',  // Adjust the width of the first chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <BarAndLineGraph />
              </Box>
              <Box sx={{
                width: '100%',  // Adjust the width of the second chart
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <LineChart />
              </Box>
            </Box>
          )}

          {chartType === "program_success" && <NoMaxWidthTooltip title={<React.Fragment><Box>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Market Growth Rate (%) = {' '}
              {'('}
              (Enrollment in the Latest Year - Enrollment in the Earliest Year)/Enrollment in the Earliest Year
              {')'}
              &times; 100
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Relative Market Share (%) = {' '}
              {'('}
              Program’s Market Share/Largest Market Share in the Same Context
              {')'}
            </Typography>
            <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              Program’s Market Share = {' '}
              {'('}
              Enrollment of the Program/Total Enrollment of All Programs
              {')'}
            </Typography>
          </Box></React.Fragment>}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </NoMaxWidthTooltip>}
        </Box>
      </div>
      </>

    </Box>


  )
}
