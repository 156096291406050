import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import '../styling/custom-header-cell.css';
import Typography from '../../../components/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import axios from "axios";
import FullFeaturedCrudGrid from "./DataGrid"
import { Auth } from "aws-amplify";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import Table9 from '../Sections/Table9-1';

// import DashboardContext from '../../context';
import { useMediaQuery } from "@mui/material";
const AdminComponent = () => {
  return (
    <Box sx={{ maxWidth: '60.75em' }}>
      <FullFeaturedCrudGrid />
    </Box>

  );
};

export default function Blueprint() {
  // const context = useContext(DashboardContext);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [cardData, setCardData] = useState(null);
  const [cardcolor, setCardcolor] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
  }, []);

  return (
    <div style={{ height: '100%', width: '100%', marginLeft: isDesktop ? '312px' : '0px', padding: '4em', }}>
      <Box sx={{
        display: 'flex', justifyContent: 'center',
        // mt:"4em" 
      }}>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          {/* BLUEPRINT FOR SUCCESS */}
          Data-Informed Intervention
        </Typography>
      </Box>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" component="span">
          {/* SMART Actions */}
          Table 9-1
        </Typography>
        <Typography variant="body" component="span">
          {/* SMART Actions */}
          Outcomes of Societal Impact Activities and Initiatives Across all Areas of the
          Standards (Table is Optional for both CIR and Initial Reports)
        </Typography>
        <div style={{
          width: '100%',
          paddingTop:'1vh'
        }}>
          {/* <AdminComponent /> */}
          <Table9></Table9>
        </div>
      </div>
    </div>
  );
}

