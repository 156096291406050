import * as React from "react";
// import Button from "../components/Button";
// import Typography from "../components/Typography";
// import MainBannerLayout from "./MainBannerLayout";
// import Box from "@mui/material/Box";
import { animated, useTrail, config } from "react-spring";
import videoFile from '../../sindex_video.mp4';
import { styled } from '@mui/material/styles';

// const AnimatedHeadings = ({ headings }) => {
//   const trail = useTrail(headings.length, {
//     from: { opacity: 0, transform: "translateX(100%)" },
//     to: { opacity: 1, transform: "translateX(0)", color: "#69696a" },
//     config: config.slow,
//     delay: 500, // Delay between each heading
//   });

//   return (
//     <div style={{ fontSize: "1.2em" }}>
//       {trail.map((styles, index) => (
//         <animated.p style={styles}>{headings[index]}</animated.p>
//       ))}
//     </div>
//   );
// };
const MainBannerLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'block',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    // height: '120vh',
    minHeight: 200,
    maxHeight: 1300,
    paddingTop: "5em"
  },
}));
export default function MainBanner() {
  return (<>
    <MainBannerLayoutRoot>
      <video src={videoFile} autoPlay loop muted style={{ objectFit: 'contain', width: '100%', height: '100%', display: "block" }}>
        Your browser does not support the video tag.
      </video>
    </MainBannerLayoutRoot>
  </>
  );
}
