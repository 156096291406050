import React from 'react';
import Plot from 'react-plotly.js';

const BarAndLineGraph = () => {
  // Data for bar and line graph
  const data = [
    {
      type: 'bar',
      x: [100, 150, 200],
      y: [20, 25, 22], // Transfer students percentage
      name: 'Transfer Students',
      marker: { color: '#8ea9b0' }, 
    },
    {
      type: 'bar',
      x: [100, 150, 200],
      y: [80, 75, 78], // Freshman students percentage
      name: 'Freshman Students',
      marker: { color: '#e1f0e1' }, // Red color
    },
    {
      type: 'scatter',
      x: [100, 150, 200],
      y: [74.2, 90.5, 80.8], // Example line data for a program
      mode: 'lines+markers',
      name: 'Current Stats- MBA',
      line: { color:  '#fbbbbb' },
    },
    {
      type: 'scatter',
      x: [100, 150, 200],
      y: [93.0, 94.2, 95.5], // Example line data for a program
      mode: 'lines+markers',
      name: 'Goal Stats-MBA',
      line:{ color:  '#977070' },
    },
  ];
  // Layout configuration
  const layout = {
    title: 'Graduation Rate For Students Current Year',
    xaxis: {
      title: '',
      tickvals: [100, 150, 200],
      ticktext: ['100% Graduation', '150% Graduation', '200% Graduation'],
    },
    yaxis: { title: 'Graduation Rate', range: [0.00, 100.00] }, // Adjust the range based on your data
    barmode: 'stack', // 'stack' for stacked bars
    legend: { orientation: 'h', y: -0.2 }, // Horizontal legend
  };

  return (
    <Plot
      data={data}
      layout={layout}
    />
  );
};

export default BarAndLineGraph;
