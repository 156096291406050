import React from 'react';
import {
    Box,
    Divider,
    Stack,
    Button,
    ButtonBase
} from '@mui/material';
import Typography from '../../components/Typography';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import { useNavigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useEffect, useState, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';
import DashboardContext from '../../context';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "../styling/tour.css";

function Sidenav() {
    const context = useContext(DashboardContext);
    const [loading, setLoading] = useState(false);
    const [isMenuToggled, setIsMenuToggled] = useState(true);

    const [activeSidenavSelection, setActiveSidenavSelection] = useState("portfolio-review");
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const primaryColor = theme.palette.primary;
    const secondaryColor = theme.palette.secondary;
    useEffect(() => {
        driverObj.drive();
    }, []);

    const driverObj = driver({
        popoverClass: 'driverjs-theme',
        showProgress: true,
        steps: [
            { popover: { title: 'Welcome to Programmatics! ', description: "<img src='/programmatics_logo.png' style='width: 100%;margin-top: 1rem;' /><span style='display: block; margin-top: 1rem; '> This tour is designed to acquaint you with the various features of Programmatics, a SaaS application, and guide you through the initial setup process. Welcome aboard!</span>" } },
            { element: '#diagnostic-diversity', popover: { title: 'Program Review', description: ' Conduct an analysis of Program and Student Success by examining program performance using the BCG (Boston Consulting Group) Matrix. Evaluate the status of each program in terms of student graduation and retention rates.', side: "left", align: 'start' } },
            { element: '#diagnostic-inclusion', popover: { title: 'Program Optimization', description: 'Assess the potential future success of the programs by forecasting their standings using the BCG Matrix.', side: "left", align: 'start' } },
            { element: '#blueprint', popover: { title: 'Data-Informed Intervention', description: 'Develop strategic objectives to address gaps within the portfolio. Utilize this blueprint to guide your efforts towards enhancing the success of the program portfolio.', side: "left", align: 'start' } },
            { element: '#success-tracker', popover: { title: 'Impacts of Intervention', description: 'Continuously monitor the progress of your portfolio. Track your advancement towards achieving a more successful portfolio.', side: "left", align: 'start' } },
            { element: '#upload-data', popover: { title: 'Upload Data', description: 'Facilitate seamless uploading and analysis of portfolio data from your organization to derive valuable insights and inform decision-making processes pertaining to strategic initiatives.', side: "left", align: 'start' } },
            // { element: '#logout-button', popover: { title: 'Logout Button', description: 'Click on \"LOGOUT\" button to sign out from the breave spaces application.', side: "bottom", align: 'start' } },
            { popover: { title: 'Tour Ended! ', description: "<img src='/programmatics_logo.png' style='width: 100%;margin-top: 1rem;' /><span style='display: block; margin-top: 1rem; '> Thank you for completing the Programmatics tour! You can revisit this tour at any time by clicking the \"Open Tour\" button.</span>" } }
        ]
    });

    return (
        <React.Fragment>
            {/* <DashboardContext.Provider value={{ statsData, companyData, setCompanyData, censusData, genderData, ethnicityData, raceData, disableData, veteranData }}> */}
            {isDesktop && <TopNav />}
            <div style={{ display: 'flex', paddingTop: "5em" }}>
                { !isMenuToggled && <div style={{ left: 10, zIndex: 100, padding: '1em', position: 'fixed' }}>
                    <button style={{ borderRadius: 5, padding: 3, display: 'flex', alignContent: 'center', justifyContent: 'ceneter', backgroundColor: secondaryColor.main }} onClick={() => setIsMenuToggled(!isMenuToggled)}>
                        <ListIcon />
                    </button>
                </div>}
                { isMenuToggled && <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '300px',
                        backgroundColor: secondaryColor.dark,
                        position: 'fixed',
                    }}
                ><div style={{ display: 'flex', justifyContent: 'end', left: 10, zIndex: 100, padding: '1em', width: '100%' }}>
                        <button style={{ borderRadius: 5, padding: 3, display: 'flex', alignContent: 'center', justifyContent: 'ceneter', backgroundColor: secondaryColor.main }} onClick={() => setIsMenuToggled(!isMenuToggled)}>
                            <ListIcon />
                        </button>
                    </div>
                    <Box sx={{ p: 3, 
                        backgroundColor: secondaryColor.dark
                         }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                // backgroundColor: secondaryColor.dark,
                                // borderRadius: 1,
                                backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                // backgroundImage: 'linear-gradient(to right top, #413631, #675951, #8f7f74, #b8a798, #e3d1be)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 2,
                                p: '12px'
                            }}
                        >
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center", flexDirection: 'column'
                            }}>
                                <Box
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {!loading && <Box
                                        component="img"
                                        // src={context.companyData.company_code === 'EI01' ? '/Academy_Logo.png' : context.companyData.company_code === 'BS01' ? '/brave_spaces_logo.png' : '/foodbank.png'}
                                        src='/eb_logo.png'
                                        alt={""}
                                        sx={{
                                            width: 60,
                                        }}
                                        /*onClick={() => { navigate(userGroup === 'education' ? '/education' : '/foodbank') }}*/
                                        onClick={() => { navigate('/programmatics') }}
                                    />}

                                </Box>

                                <Typography
                                    color="white"
                                    variant="body2"
                                    alignItems="center"
                                    fontSize='1em'
                                    sx={{ textAlign: 'center' }}
                                >
                                    Cal State East Bay,
                                </Typography>
                                <Typography
                                    color="white"
                                    variant="body2"
                                    alignItems="center"
                                    fontSize='1em'
                                    sx={{ textAlign: 'center' }}

                                >
                                    College of Business & Economics
                                </Typography>
                            </div>

                        </Box>
                    </Box>
                    <Divider sx={{ borderColor: 'primary.light' }} />
                    <Box
                        sx={{
                            flexGrow: 1,
                            px: 2,
                            py: 3,
                            backgroundColor: secondaryColor.dark,
                        }}
                    >
                        <Stack
                            component="ul"
                            spacing={0.5}
                            sx={{
                                listStyle: 'none',
                                p: 0,
                                m: 0,
                            }}
                        >
                            <Box x={{
                                display: 'flex',
                                flexDirection: "column",
                                gap: "0.2em",
                            }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        color: 'white',
                                        padding: ' 0.5em',
                                        borderRadius: 1,
                                        marginBottom: "0.5em",
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                    }}
                                >
                                    <Box sx={{ paddingRight: '1em' }}><BarChartOutlinedIcon /></Box>
                                    Program Health
                                </Box>
                                <Box
                                    id="diagnostic-diversity"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        padding: '0.1em 0.1em 0.1em 2em',
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                        marginBottom: "0.5em",
                                        ...(activeSidenavSelection === "portfolio-review" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                    <ButtonBase
                                        sx={{
                                            fontSize: '0.75rem',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("portfolio-review"); navigate('/programmatics') }}
                                    >
                                        Program Review
                                    </ButtonBase>
                                </Box>
                                <Box
                                    id="diagnostic-inclusion"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        padding: '0.1em 0.1em 0.1em 2em',
                                        // paddingLeft: '1.5em',
                                        borderRadius: 1,
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                        ...(activeSidenavSelection === "portfolio-optimization" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: '1em' }}><KeyboardDoubleArrowRightOutlinedIcon /></Box>
                                    <ButtonBase
                                        style={{
                                            fontSize: '0.75rem', display: "flex", justifyContent: "center", alignItems: "center",
                                        }}
                                        onClick={() => { setActiveSidenavSelection("portfolio-optimization"); navigate('/programmatics/portfolioOptimization') }}
                                    >
                                        Program Optimization
                                    </ButtonBase>
                                </Box>
                                <Box
                                    id="blueprint"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        padding: ' 0.5em',
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                        ...(activeSidenavSelection === "blueprint" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em',
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            fontSize: '1em',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("blueprint"); navigate('/programmatics/blueprint') }}

                                    >
                                        <Box sx={{ paddingRight: '1em' }}><SettingsSuggestOutlinedIcon /></Box>
                                        Data-Informed Intervention
                                    </ButtonBase>
                                </Box>
                                <Box
                                    id="success-tracker"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        padding: ' 0.5em',
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                        ...(activeSidenavSelection === "success-tracker" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            fontSize: '1em',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("success-tracker"); navigate('/programmatics/tracker') }}
                                    >
                                        <Box sx={{ paddingRight: '1em' }}><AutoGraphOutlinedIcon /></Box>
                                        Impacts of Intervention
                                    </ButtonBase>
                                </Box>
                                <Box
                                    id="upload-data"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        borderRadius: 1,
                                        marginTop: '0.5em',
                                        marginBottom: '0.5em',
                                        padding: ' 0.5em',
                                        backgroundColor: 'rgba(255, 247, 237, 0.1)',
                                        ...(activeSidenavSelection === "upload-data" && {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }),
                                        '&:hover': {
                                            backgroundColor: secondaryColor.main,
                                            color: 'black',
                                            transition: 'all 0.9s',
                                        }
                                    }}
                                >
                                    <ButtonBase
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            fontSize: '1em',
                                        }}
                                        onClick={() => { setActiveSidenavSelection("upload-data"); navigate('/programmatics/upload') }}

                                    >
                                        <Box sx={{ paddingRight: '1em', display: "flex", justifyContent: "center", alignItems: "center" }}><UploadFileIcon /></Box>
                                        Upload Data
                                    </ButtonBase>
                                </Box>
                            </Box>
                        </Stack>
                        <Button style={{ width: "100%", color: "#FFF7ED", borderColor: "#FFF7ED", bottom: '0px' }} variant="outlined" onClick={() => driverObj.drive()}>Open Tour</Button>
                    </Box>
                    <Divider sx={{ borderColor: 'white' }} />
                </Box>}
                <Box sx={{ width: '100%', marginLeft: isMenuToggled ? '300px' : '0px' }}><Outlet /></Box>
            </div>
        </React.Fragment >
    )
}
export default withAuthenticator(Sidenav)
// export default Sidenav
