export const annualGrowthRateDataMBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: '2018',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -40.90909091,
                333.3333333,
                -21.81818182,
                177.7777778,
                -7.407407407,
                -72.72727273,
                -17.64705882,
                24,
                28.57142857
            ], // Freshman students percentage
            name: '2019',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                34.61538462,
                100,
                -18.60465116,
                28,
                20,
                -100,
                0,
                -22.58064516,
                50
            ], // Freshman students percentage
            name: '2020',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-25.71428571,
                3.846153846,
                5.714285714,
                40.625,
                63.33333333,
                0,
                42.85714286,
            -4.166666667,
            -11.11111111
            ], // Freshman students percentage
            name: '2021',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-38.46153846,
            -14.81481481,
            -21.62162162,
            -24.44444444,
            -8.163265306,
                0,
                45,
                4.347826087,
            -8.333333333
            ], // Freshman students percentage
            name: '2022',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-50,
            -8.695652174,
            -34.48275862,
                17.64705882,
            -28.88888889,
                0,
            -3.448275862,
            -41.66666667,
            -13.63636364
            ], // Freshman students percentage
            name: '2023',
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Growth Rate of MBA Programs by year',
        plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['MBA without Concentration(s)',
                'Analytics for managers/Analytics for managers MBA',
                'Finance',
                'General business',
                'HRM&OB/HR&OB',
                'ITM',
                'Marketing management',
                'OSCM',
                'Strategy and innovation'], tickangle: -45
        },
        yaxis: { title: 'Annual Graduation Rate', range: [-200, 450.00] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}

export const annualGrowthRateDataBSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: '2018',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -9.468438538,
                -6.932773109,
                -2.222222222,
                12.5,
                -10.81081081,
                21.7791411,
                14.08695652,
                11.41304348,
                8.962264151

            ], // Freshman students percentage
            name: '2019',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -0.183486239,
                -4.966139955,
                25,
                -33.33333333,
                -3.03030303,
                1.259445844,
                4.268292683,
                5.365853659,
                2.597402597

            ], // Freshman students percentage
            name: '2020',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -4.227941176,
                2.612826603,
                -12.72727273,
                33.33333333,
                -8.59375,
                -2.736318408,
                -8.333333333,
                1.851851852,
                -0.843881857

            ], // Freshman students percentage
            name: '2021',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -7.677543186,
                -0.694444444,
                -35.41666667,
                -50,
                -2.564102564,
                -10.99744246,
                -8.612440191,
                -5.454545455,
                -10.63829787

            ], // Freshman students percentage
            name: '2022',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -2.702702703,
                7.925407925,
                -25.80645161,
                -50,
                1.754385965,
                -11.20689655,
                -2.617801047,
                18.75,
                16.19047619

            ], // Freshman students percentage
            name: '2023',
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Growth Rate of BSBA Programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['BSBA without Concentration(s)',
                'Accounting',
                'Business economics',
                'Corporate management',
                'Entrepreneurship',
                'Finance',
                'General business/General Management',
                'HRM&OB/HRM',
                'ITM'
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Graduation Rate', range: [-60, 50] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v' }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}

export const annualGrowthRateDataBSBAO = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: '2018',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -54.43037975,
                50,
                -100,
                100,
                0,
                57.14285714,
                19.89795918,
                0,
                36,
                -50,
                23.52941176

            ], // Freshman students percentage
            name: '2019',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [
                -8.333333333,
                900,
                0,
                -16.66666667,
                0,
                8.391608392,
                8.510638298,
                0,
                14.70588235,
                -100,
                14.28571429

            ], // Freshman students percentage
            name: '2020',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-69.6969697,
                230,
                0,
                40,
                0,
                0.64516129,
            -2.352941176,
                252.6315789,
                38.46153846,
                0,
                39.58333333
            ], // Freshman students percentage
            name: '2021',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-70,
                81.81818182,
                0,
            -57.14285714,
            -100,
            -10.8974359,
            -2.008032129,
                22.3880597,
            -15.74074074,
                750,
                2.985074627

            ], // Freshman students percentage
            name: '2022',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [-66.66666667,
                26.11111111,
                0,
            -33.33333333,
                0,
            -12.94964029,
            -13.52459016,
                58.53658537,
                20.87912088,
                82.35294118,
                0

            ], // Freshman students percentage
            name: '2023',
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Growth Rate of BSBA Online Programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['BSBA Online without Concentration(s)',
                'Accounting',
                'Business economics',
                'Corporate management',
                'Entrepreneurship',
                'Finance',
                'General business/General Management',
                'HRM&OB/HRM',
                'ITM',
                'Marketing management',
                'OSCM',
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Graduation Rate', range: [-200, 1000.00] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}

export const annualGrowthRateDataTOP = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0], // Transfer students percentage
            name: '2018',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [-3.414634146,
                3.338509317,
                16.59388646,
                5.319148936,
                0,
                0,
                0,
            -12.35955056,

            ], // Freshman students percentage
            name: '2019',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [12.62626263,
                1.164537941,
                16.66666667,
                -18.18181818,
                0,
                0,
                0,
                11.53846154


            ], // Freshman students percentage
            name: '2020',
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [12.55605381,
                -3.490531006,
                23.27447833,
                -6.172839506,
                0,
                0,
                0,
                -20.68965517

            ], // Freshman students percentage
            name: '2021',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [-11.55378486,
            -7.733743748,
                10.02604167,
            -21.05263158,
                0,
                0,
                0,
            -28.98550725,


            ], // Freshman students percentage
            name: '2022',
        }, {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80,],
            y: [-18.46846847,
                1.334445371,
                10.53254438,
                0,
                0,
                0,
                0,
                0


            ], // Freshman students percentage
            name: '2023',
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Growth Rate of All the Programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80],
            ticktext: ['MBA with Concentration(s)',
                'BSBA with Concentration(s)',
                'BSBA Online with Concentration(s)',
                'Executive MBA for Global Innovators',
                'One-Year Intensive MBA',
                'BS Business Analytics with Concentration(s)',
                'BS Accounting and Analytics',
                'BS Economics',
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Graduation Rate', range: [-50, 50] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}








export const annualMarketShareDataMBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [21.46341463, 1.463414634, 26.82926829, 4.390243902, 13.17073171, 5.365853659, 8.292682927, 12.19512195, 6.829268293], // 2018 percentages
            name: '2018'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [13.13131313, 6.565656566, 21.71717172, 12.62626263, 12.62626263, 1.515151515, 7.070707071, 15.65656566, 9.090909091], // 2019 percentages
            name: '2019'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [15.69506726, 11.65919283, 15.69506726, 14.34977578, 13.4529148, 0, 6.278026906, 10.76233184, 12.10762332], // 2020 percentages
            name: '2020'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [10.35856574, 10.75697211, 14.74103586, 17.92828685, 19.52191235, 0, 7.96812749, 9.163346614, 9.561752988], // 2021 percentages
            name: '2021'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [7.207207207, 10.36036036, 13.06306306, 15.31531532, 20.27027027, 0, 13.06306306, 10.81081081, 9.90990991], // 2022 percentages
            name: '2022'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [4.419889503, 11.60220994, 10.49723757, 22.09944751, 17.67955801, 0, 15.46961326, 7.73480663, 10.49723757], // 2023 percentages
            name: '2023'
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Relative Market Share of MBA programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['MBA with Concentration(s)',
                'BSBA with Concentration(s)',
                'BSBA Online with Concentration(s)',
                'Executive MBA for Global Innovators',
                'One-Year Intensive MBA',
                'BS Business Analytics with Concentration(s)',
                'BS Accounting and Analytics',
                'BS Economics',
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Relative Market Share', range: [0, 30] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}

export const annualMarketShareDataBSBA = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [23.36956522, 18.47826087, 1.74689441, 0.310559006, 5.745341615, 12.6552795, 22.32142857, 7.142857143, 8.229813665], // 2018 percentages
            name: '2018'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [20.47332832, 16.64162284, 1.652892562, 0.33809166, 4.958677686, 14.9135988, 24.64312547, 7.700976709, 8.67768595], // 2019 percentages
            name: '2019'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [20.20051987, 15.63312291, 2.042331972, 0.222799851, 4.753063498, 14.92759005, 25.39918307, 8.020794653, 8.800594133], // 2020 percentages
            name: '2020'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [20.0461716, 16.62177761, 1.846864179, 0.307810696, 4.501731435, 15.04424779, 24.12466333, 8.464794152, 9.041939207], // 2021 percentages
            name: '2021'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [20.05838198, 17.88990826, 1.292743953, 0.166805671, 4.753961635, 14.51209341, 23.89491243, 8.673894912, 8.757297748], // 2022 percentages
            name: '2022'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            y: [19.25925926, 19.05349794, 0.946502058, 0.082304527, 4.773662551, 12.71604938, 22.96296296, 10.16460905, 10.04115226], // 2023 percentages
            name: '2023'
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Relative Market Share of BSBA programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ["BSBA without Concentration(s)",
                "BSBA: Accounting",
                "Business economics",
                "Corporate management",
                "Entrepreneurship",
                "Finance",
                "General business/General Management",
                "HRM&OB/HRM",
                "ITM",
                "Marketing management",
                "OSCM"

            ], tickangle: -45
        },
        yaxis: { title: 'Annual Relative Market Share', range: [0, 30] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}


export const annualMarketShareDataBSBAO = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [17.2489083, 0.436681223, 0.218340611, 0.655021834, 0, 19.86899563, 42.79475983, 0, 10.91703057, 0.436681223, 7.423580786], // 2018 percentages
            name: '2018'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [6.741573034, 0.561797753, 0, 1.123595506, 0, 26.77902622, 44.00749064, 0, 12.7340824, 0.187265918, 7.865168539], // 2019 percentages
            name: '2019'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [5.296950241, 4.81540931, 0, 0.802568218, 0, 24.87961477, 40.93097913, 3.04975923, 12.52006421, 0, 7.704654896], // 2020 percentages
            name: '2020'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [1.302083333, 12.890625, 0, 0.911458333, 0.130208333, 20.3125, 32.421875, 8.723958333, 14.0625, 0.520833333, 8.723958333], // 2021 percentages
            name: '2021'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [0.355029586, 21.30177515, 0, 0.355029586, 0, 16.44970414, 28.87573964, 9.704142012, 10.76923077, 4.023668639, 8.165680473], // 2022 percentages
            name: '2022'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            y: [0.107066381, 24.30406852, 0, 0.214132762, 0.107066381, 12.95503212, 22.59100642, 13.91862955, 11.77730193, 6.638115632, 7.3875803], // 2023 percentages
            name: '2023'
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Relative Market Share of BSBA Online programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: [
                'BSBA Online without Concentration(s)',
                'Accounting',
                'Business economics',
                'Corporate management',
                'Entrepreneurship',
                'Finance',
                'General business/General Management',
                'HRM&OB/HRM',
                'ITM',
                'Marketing management',
                'OSCM'
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Relative Market Share', range: [0, 50] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}

export const annualMarketShareDataAll = {
    data: [
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [5.634964266, 70.80813634, 12.5893348, 2.583837273, 0, 0, 0, 2.44639912, 4.068169324, 0.824628917, 1.044529962, 0], // 2018 percentages
            name: '2018'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [5.191400105, 69.7954903, 14.00104877, 2.595700052, 0, 0, 0, 2.045097011, 4.509701101, 1.206082853, 0.655479811, 0], // 2019 percentages
            name: '2019'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [5.637007078, 68.07381193, 15.74823054, 2.04752275, 0, 0, 0, 2.199191102, 4.271991911, 1.49140546, 0.530839232, 0], // 2020 percentages
            name: '2020'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [6.259351621, 64.81296758, 19.1521197, 1.895261845, 0, 0, 0, 1.720698254, 4.239401496, 1.44638404, 0.473815461, 0], // 2021 percentages
            name: '2021'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [5.656050955, 61.0955414, 21.52866242, 1.52866242, 0, 0, 0, 1.248407643, 7.363057325, 1.248407643, 0.331210191, 0], // 2022 percentages
            name: '2022'
        },
        {
            type: 'bar',
            x: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
            y: [4.526131533, 60.7651913, 23.35583896, 1.500375094, 0, 0, 0, 1.225306327, 7.626906727, 1.000250063, 0, 0], // 2023 percentages
            name: '2023'
        },
    ],
    // Layout configuration
    layout: {
        title: 'Annual Relative Market Share of All programs by year', plot_bgcolor: '#F5EDE5', paper_bgcolor: '#F5EDE5',
        xaxis: {
            title: '', automargin: true,
            tickvals: [10, 20, 30, 40, 50, 60, 70, 80, 90],
            ticktext: ['MBA with Concentration(s)',
                'BSBA with Concentration(s)',
                'BSBA Online with Concentration(s)',
                'Executive MBA for Global Innovators',
                'One-Year Intensive MBA',
                'BS Business Analytics with Concentration(s)',
                'BS Accounting and Analytics',
                'BS Economics',
                'MS Business Analytics',
                'MS Accountancy',
                'MS Quantative Economics',
                'MS Marketing Analytics'
            ], tickangle: -45
        },
        yaxis: { title: 'Annual Relative Market Share', range: [0, 75] }, // Adjust the range based on your data
        // barmode: 'stack', // 'stack' for stacked bars
        legend: { orientation: 'v', }, // Horizontal legend
        // width: 1000,
        height: 700,
        autosize: true,
    }
}